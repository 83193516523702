import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_LANGUAGES } from './core/constants';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { GlobalState } from './core/store';
import { appSelectors } from './core/store/app';
import { Language } from './core/models/app.models';
import { AppComponentActions } from './core/store/actions';
import { appCustomSelectors } from 'src/app/core/store/app/app.selectors';

@Component({
  selector: 'izzo-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  isLoggedIn: Observable<boolean> = this.store.select(
    appSelectors.selectLoggedIn,
  );
  currentLanguage$: Observable<Language> = this.store.select(
    appSelectors.selectLanguage,
  );
  subscription$ = this.store.select(appSelectors.selectSubscription);
  roles$ = this.store.select(appCustomSelectors.selectRoles);
  languages = SUPPORTED_LANGUAGES;

  constructor(
    private translate: TranslateService,
    private store: Store<GlobalState>,
  ) {
    translate.addLangs(SUPPORTED_LANGUAGES);
  }

  onLanguageClicked(language: string) {
    this.store.dispatch(
      AppComponentActions.languageClicked({ language: language as Language }),
    );
  }

  onLogoutClicked() {
    this.store.dispatch(AppComponentActions.logoutClicked());
  }

  onUploadEventClicked() {
    this.store.dispatch(AppComponentActions.uploadEventClicked());
  }
}
