import { createFeature, createReducer, on } from '@ngrx/store';
import { CrmOverviewContainerActions } from './actions';
import { CrmOverviewState } from './crm-overview.state';

export const initialState: Readonly<CrmOverviewState> = {
  searchText: '',
  clientsOverviewData: [],
  deleteClient: null,
};

export const crmOverviewReducers = createReducer(
  initialState,
  on(CrmOverviewContainerActions.searchSuccess, (state, { data }) => ({
    ...state,
    clientsOverviewData: data,
  })),
  on(CrmOverviewContainerActions.deleteClient, (state, { id }) => ({
    ...state,
    deleteClient: id,
  })),
  on(CrmOverviewContainerActions.deleteClientSuccess, (state, {}) => ({
    ...state,
    deleteClient: null,
  })),
);

export const crmOverviewFeature = createFeature({
  name: 'crmOverview',
  reducer: crmOverviewReducers,
});
