import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  EMPTY,
  catchError,
  exhaustMap,
  filter,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { FileDownloadService } from 'src/app/core/services/file-download.service';
import { ListService } from 'src/app/core/services/list.service';
import { OrbitContainerActions } from 'src/app/core/store/actions';
import { appSelectors } from 'src/app/core/store/app';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { MyListContainerActions, myListSelectors } from '.';
import { FormService } from '../../../core/services/form.service';
import { GlobalState } from '../../../core/store';
import { orbitSelectors } from '../../orbit/store';
import { OrbitState } from '../../orbit/store/orbit.state';

@Injectable({
  providedIn: 'root',
})
export class MyListEffects {
  #toastService = inject(ToastService);
  #actions = inject(Actions);
  #translate = inject(TranslateService);

  patchEditValues$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyListContainerActions.editInviteeClicked),
        tap((editInvitee) => {
          this.formService.patchEditInviteeForms({
            firstName: editInvitee.listInvitee.firstName || '',
            name: editInvitee.listInvitee.name || '',
            comment: editInvitee.listInvitee.comment || '',
          });
        }),
      ),
    { dispatch: false },
  );

  importCsv$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyListContainerActions.importCsv),
      map((x) => ({
        file: x.file,
        acceptedTypes: ['application/vnd.ms-excel', 'text/csv'],
      })),
      filter(
        (x) =>
          !!x.file &&
          x.acceptedTypes.includes(x.file.type) &&
          x.file.name.endsWith('.csv'),
      ),
      tap(() =>
        this.#toastService.showInfo(
          'izzo.admin.shared.info.csvImportStarted.title',
          'izzo.admin.shared.info.csvImportStarted.message',
        ),
      ),
      withLatestFrom(this.store.select(orbitSelectors.selectOpenList)),
      switchMap(([x, list]) => {
        return this.listService.importCsv(x.file, list.id!);
      }),
      map((result) => {
        if (result.status !== 'completed') {
          return MyListContainerActions.importCsvUnsuccessful({ result });
        } else {
          return MyListContainerActions.importCsvSuccessful();
        }
      }),
    ),
  );

  importCsvSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyListContainerActions.importCsvSuccessful),
        withLatestFrom(this.store),
        tap(() => {
          this.#toastService.showSuccess(
            'izzo.admin.shared.success.csvImport.title',
            'izzo.admin.shared.success.csvImport.message',
            { dismiss: 5000 },
          );
        }),
      ),
    { dispatch: false },
  );

  importCsvUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyListContainerActions.importCsvUnsuccessful),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ result }]) => {
          this.#toastService.showError(result!.details!);
        }),
      ),
    { dispatch: false },
  );

  exportCsv$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(MyListContainerActions.exportCsvLInviteesFromList),
        tap(() =>
          this.#toastService.showInfo(
            'izzo.admin.shared.info.csvExportStarted.title',
            'izzo.admin.shared.info.csvExportStarted.message',
          ),
        ),
        exhaustMap(({ listId }) =>
          forkJoin({
            fileName: this.#translate.get('izzo.admin.list.inviteesList').pipe(
              withLatestFrom(this.store.select(myListSelectors.selectList)),
              map(([x, list]) => ({
                name: x + ' ' + list.name,
                today: new Date().toISOString().split('T')[0],
              })),
              map(({ name, today }) => `${name}_${today}.csv`),
            ),
            response: this.listService.exportCsvByListId(listId),
          }).pipe(
            catchError(() => {
              this.#toastService.showError(
                'izzo.admin.shared.errors.csvExport',
                'izzo.admin.shared.errors.unexpected',
              );
              return EMPTY;
            }),
            filter((x) => !!x.response.body),
            tap((x) =>
              this.fileDownloadService.downloadBlob(
                x.response.body!,
                x.fileName,
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  getCsvTemplateClick$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(MyListContainerActions.getCsvTemplateClick),
        tap(() =>
          this.#toastService.showInfo(
            'izzo.admin.shared.info.downloadStarted.title',
            'izzo.admin.shared.info.downloadStarted.message',
          ),
        ),
        exhaustMap(() =>
          forkJoin({
            fileName: this.#translate.get('izzo.admin.list.templateName'),
            response: this.listService.getCsvTemplate(),
          }).pipe(
            catchError(() => {
              this.#toastService.showError(
                'izzo.admin.shared.errors.csvDownload',
                'izzo.admin.shared.errors.unexpected',
              );
              return EMPTY;
            }),
            filter((x) => !!x.response.body),
            tap((x) =>
              this.fileDownloadService.downloadBlob(
                x.response.body!,
                x.fileName,
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  addInviteeClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyListContainerActions.addInviteeToListClicked),
      switchMap(({ listInvitee }) =>
        this.listService.addInviteeToList({
          list: listInvitee.list,
          name: this.formService.getAddInviteeToListFormGroup().controls.name
            .value!,
          firstName:
            this.formService.getAddInviteeToListFormGroup().controls.firstName
              .value!,
          isCheckedIn: false,
          comment:
            this.formService.getAddInviteeToListFormGroup().controls.comment
              .value!,
          timeStamp: null,
          isReadOnly: true,
        }),
      ),
      map(() => MyListContainerActions.addInviteeToListSuccessful()),
      catchError((_error) => [
        MyListContainerActions.addInviteeToListUnsuccessful(),
      ]),
    ),
  );

  editInviteeToListClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyListContainerActions.confirmEditInviteeClicked),
      withLatestFrom(this.store.select(orbitSelectors.selectOpenList)),
      switchMap(([listInvitee, list]) =>
        this.listService.editInviteeToList({
          id: listInvitee.listInvitee.id,
          list: list,
          name: this.formService.getEditInviteeFormGroup().controls.name.value!,
          firstName:
            this.formService.getEditInviteeFormGroup().controls.firstName
              .value!,
          isCheckedIn:
            this.formService.getAddInviteeToListFormGroup().controls.isCheckedIn
              .value!,
          comment:
            this.formService.getEditInviteeFormGroup().controls.comment.value!,
          timeStamp: null,
          isReadOnly: false,
        }),
      ),
      map(() => MyListContainerActions.editInviteeToListSuccessful()),
      catchError((_error) => [
        MyListContainerActions.editInviteeToListUnsuccessful(),
      ]),
    ),
  );
  checkInInviteeClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyListContainerActions.checkInOutInvitee),
      withLatestFrom(this.store.select(myListSelectors.selectList)),
      switchMap(([listInvitee, list]) =>
        this.listService.checkInOutInvitee({
          id: listInvitee.listInvitee.id,
          list: list,
          firstName: listInvitee.listInvitee.firstName,
          name: listInvitee.listInvitee.name,
          isCheckedIn: listInvitee.listInvitee.isCheckedIn,
          comment: listInvitee.listInvitee.comment,
          timeStamp: null,
          isReadOnly: true,
        }),
      ),
      map(() => MyListContainerActions.checkInInviteeSuccessful()),
    ),
  );
  listOpened$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        OrbitContainerActions.openListClicked,
        MyListContainerActions.addInviteeToListSuccessful,
        MyListContainerActions.importCsvSuccessful,
        MyListContainerActions.importCsvUnsuccessful,
      ),
      withLatestFrom(this.store.select(orbitSelectors.selectOpenList)),
      switchMap(([_, list]) =>
        this.listService.getListInviteesByListId(list!.id!),
      ),
      map((listInvitees) =>
        MyListContainerActions.listOpened({ listInvitees }),
      ),
    ),
  );
  addInviteeSuccesful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyListContainerActions.addInviteeToListSuccessful),
        withLatestFrom(this.store),
        tap(() => {
          this.#toastService.showSuccess(
            'izzo.admin.list.addInvitee.success.title',
            'izzo.admin.list.addInvitee.success.text',
            { dismiss: 5000 },
          );

          this.formService.getAddInviteeToListFormGroup().reset({
            firstName: '',
            name: '',
            comment: '',
          });
        }),
      ),
    { dispatch: false },
  );

  addInviteeUnsuccesful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyListContainerActions.addInviteeToListUnsuccessful),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.list.addInvitee.error.title',
            'izzo.admin.list.addInvitee.error.text',
          );
        }),
      ),
    { dispatch: false },
  );

  editInviteeToListSuccessful$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyListContainerActions.editInviteeToListSuccessful),
      withLatestFrom(this.store.select(myListSelectors.selectList)),
      tap(() =>
        this.#toastService.showSuccess(
          'izzo.admin.list.editInvitee.success.title',
          'izzo.admin.list.editInvitee.success.text',
        ),
      ),
      switchMap(([_, list]) =>
        this.listService.getListInviteesByListId(list!.id!),
      ),
      map((listInvitees) =>
        MyListContainerActions.listOpened({ listInvitees }),
      ),
    ),
  );

  editInviteeUnsuccesful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyListContainerActions.editInviteeToListUnsuccessful),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.list.editInvitee.error.title',
            'izzo.admin.list.editInvitee.error.text',
          );
        }),
      ),
    { dispatch: false },
  );

  deleteInvitee$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyListContainerActions.deleteInviteeClicked),
      switchMap(({ listInvitee }) =>
        this.listService.deleteInviteeFromList(listInvitee!.id!).pipe(
          map(() =>
            MyListContainerActions.deleteInviteeSuccessful({ listInvitee }),
          ),
          catchError((error) =>
            of(MyListContainerActions.deleteInviteeFailed({ listInvitee })),
          ),
        ),
      ),
    ),
  );

  deleteInviteeSuccessful$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyListContainerActions.deleteInviteeSuccessful),
      withLatestFrom(this.store.select(myListSelectors.selectList)),
      tap(() => {
        this.#toastService.showSuccess(
          'izzo.admin.list.deleteInvitee.success.title',
          'izzo.admin.list.deleteInvitee.success.text',
        );
      }),
      switchMap(([_, list]) =>
        this.listService.getListInviteesByListId(list!.id!),
      ),
      map((listInvitees) =>
        MyListContainerActions.listOpened({ listInvitees }),
      ),
    ),
  );

  deleteInviteeUnsuccesful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyListContainerActions.deleteInviteeFailed),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.list.deleteInvitee.failed.title',
            'izzo.admin.list.deleteInvitee.failed.text',
          );
        }),
      ),
    { dispatch: false },
  );

  maxInviteesReached$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyListContainerActions.maxInviteesReached),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.list.maxInviteesReached.title',
            'izzo.admin.list.maxInviteesReached.text',
          );
        }),
      ),
    { dispatch: false },
  );

  currentTimeOverValidTime$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyListContainerActions.currentTimeOverValidTime),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.list.currentTimeOverValidTime.title',
            'izzo.admin.list.currentTimeOverValidTime.text',
          );
        }),
      ),
    { dispatch: false },
  );

  backToOpenOrbitPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyListContainerActions.backClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          this.router.navigate([language, 'orbit', 'event', event.id]);
        }),
      ),
    { dispatch: false },
  );

  createNewLinkForList$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyListContainerActions.createNewLinkClicked),
      switchMap(({ list }) => this.listService.createNewLinkForList(list!)),
      map(() => MyListContainerActions.createNewLinkForListSuccessful()),
    ),
  );

  createNewLinkForListSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyListContainerActions.createNewLinkForListSuccessful),
        withLatestFrom(this.store.select(myListSelectors.selectList)),
        tap(() => {
          this.#toastService.showSuccess(
            'izzo.admin.list.createLink.success.title',
            'izzo.admin.list.createLink.success.text',
          );
        }),
        switchMap(([_, list]) => this.listService.getListById(list!.id!)),
        withLatestFrom(this.store),
        map(([list, store]) =>
          this.formService.patchListLinkFormGroup({
            link:
              environment.adminURL +
              '/' +
              store.app.language +
              '/list-readOnly/' +
              list!.link!,
          }),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<GlobalState>,
    private readonly orbitStore: Store<OrbitState>,
    private readonly formService: FormService,
    private readonly router: Router,
    private readonly listService: ListService,
    private readonly fileDownloadService: FileDownloadService,
  ) {}
}
