<div class="toast {{ type }}">
  <div class="content">
    @if (title) {
      <span class="title">{{ title | translate }}</span>
    }
    @if (text) {
      <span class="text">{{ text | translate }}</span>
    }
  </div>
  <div class="close-icon">
    <svg-icon (click)="closeToast.emit()" key="close"></svg-icon>
  </div>
</div>
