import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoginFormData } from '../models/form.models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  loginWithEmailAndPassword(loginFormData: LoginFormData): Observable<any> {
    return this.http.post(environment.api + '/worker/login', {
      username: loginFormData.email,
      password: loginFormData.password,
    });
  }

  loginWithSession(session: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${session}`,
    });
    return this.http.get(environment.api + `/session/company/${session}`);
  }

  generate2FACode(data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${data.key}`,
    });
    return this.http.post(environment.api + '/worker/2fa/generate', null, {
      headers,
      responseType: 'text',
    });
  }

  turnOn2FA(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${data.key}`,
    });
    return this.http.post(environment.api + '/worker/2fa/turn-on', data, {
      headers,
    });
  }

  authWith2FA(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${data.key}`,
    });
    return this.http.post(environment.api + '/worker/2fa/authenticate', data, {
      headers,
    });
  }
}
