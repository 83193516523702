import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  EMPTY,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ErrorHandlingService } from 'src/app/core/business-rule/error-handling.service';
import { GlobalState } from 'src/app/core/store';
import { appSelectors } from 'src/app/core/store/app';
import { CrmService } from '../../crm.service';
import { ClientCreateFormService } from '../client-create-form.service';
import { ClientCreateFormActions } from './actions';
import { selectCurrentStep } from './client-create-form.selectors';
import { ClientCreateFormState } from './client-create-form.state';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Injectable({ providedIn: 'root' })
export class ClientCreateFormEffects {
  #actions = inject(Actions);
  #formService = inject(ClientCreateFormService);
  #errorHandlingService = inject(ErrorHandlingService);
  #toastService = inject(ToastService);

  resetForm = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClientCreateFormActions.resetForm),
        tap(() => {
          this.#formService.form.reset();
        }),
      ),
    { dispatch: false },
  );
  #store = inject(Store<ClientCreateFormState>);
  #router = inject(Router);
  #globalStore = inject(Store<GlobalState>);
  #crmService = inject(CrmService);
  save = createEffect(() =>
    this.#actions.pipe(
      ofType(ClientCreateFormActions.save),
      map(() => this.#formService.form),
      filter((form) => form.valid),
      map((form) => ({
        ...form.controls.person.getRawValue(),
        street: form.controls.address.getRawValue().street?.toString() ?? '',
        zipCode: form.controls.address.getRawValue().plz?.plz.toString() ?? '',
        city:
          form.controls.address.getRawValue().plz?.province.toString() ?? '',
        place: form.controls.address.getRawValue().plz!,
      })),
      switchMap((data) =>
        this.#crmService.createClient(data).pipe(
          withLatestFrom(this.#globalStore.select(appSelectors.selectLanguage)),
          map(() => ClientCreateFormActions.createClientSuccess()),
          catchError((error) => {
            this.#errorHandlingService.handleError(
              error,
              'izzo.admin.crm.errors',
            );
            return EMPTY;
          }),
        ),
      ),
    ),
  );
  createClientSuccess = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClientCreateFormActions.createClientSuccess),
        withLatestFrom(this.#globalStore.select(appSelectors.selectLanguage)),
        tap(() => {
          this.#toastService.showSuccess('izzo.admin.crm.createSuccess');
        }),
        map(([_, lang]) => this.#router.navigate([lang, 'crm'])),
      ),
    { dispatch: false },
  );

  #stepsMap: Record<number, AbstractControl> = {
    1: this.#formService.form.controls.person,
    2: this.#formService.form.controls.address,
  };
  tryNextStep = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClientCreateFormActions.tryNextStep),
        withLatestFrom(this.#store.select(selectCurrentStep)),
        map(([action, currentStep]) => {
          const form = this.#stepsMap[currentStep];

          if (form.invalid) {
            form.markAllAsTouched();
            return { action, currentStep, canStep: false };
          }

          return { action, currentStep, canStep: true };
        }),
        filter((x) => x.canStep),
        map(({ currentStep }) =>
          ClientCreateFormActions.changeStep({ step: currentStep + 1 }),
        ),
      ),
    { dispatch: true },
  );
  tryGoToStep = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClientCreateFormActions.tryGoToStep),
        withLatestFrom(this.#store.select(selectCurrentStep)),
        map(([action, currentStep]) => {
          const form = this.#stepsMap[currentStep];
          const isPrevious = action.step < currentStep;

          if (!isPrevious && form.invalid) {
            form.markAllAsTouched();
            return { action, canStep: false };
          }

          return { action, canStep: true };
        }),
        filter((x) => x.canStep),
        map(({ action }) =>
          ClientCreateFormActions.changeStep({ step: action.step }),
        ),
      ),
    { dispatch: true },
  );
  #location = inject(Location);
  goBack$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClientCreateFormActions.back),
        map(() => {
          this.#location.back();
        }),
      ),
    { dispatch: false },
  );
}
