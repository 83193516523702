import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FormService } from '../../../core/services/form.service';
import { GlobalState } from '../../../core/store';

import {
  catchError,
  exhaustMap,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { LoungeService } from 'src/app/core/services/lounge.service';
import { ReservationService } from 'src/app/core/services/reservation.service';
import { appSelectors } from 'src/app/core/store/app';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { OrbitContainerActions, orbitSelectors } from '../../orbit/store';
import { OrbitState } from '../../orbit/store/orbit.state';
import { PackageApiService } from '../../package/package-api.service';
import { EditLoungeState } from './edit-lounge.state';
import {
  EditLoungeContainerActions,
  EditLoungeEffectsActions,
  editLoungeSelectors,
} from './index';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class EditLoungeEffects {
  patchEditValues$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        OrbitContainerActions.editLoungeClicked,
        EditLoungeContainerActions.loungeInitialized,
      ),
      tap(({ lounge }) => {
        this.formService.patchEditLoungeFormGroup({
          name: lounge.name || '',
          numberSeats: lounge.numberSeats,
          comment: lounge.comment || '',
          loungeType: lounge.loungeType?.id!,
        });
        this.formService.patchReservationFormGroup({
          name: lounge.reservation?.name || '',
          email: lounge.reservation?.email || '',
          telephone: lounge.reservation?.telephone || '',
          numberPersons: lounge.reservation?.numberPersons || 0,
          arrival: lounge.reservation?.arrival || '',
          comment: lounge.reservation?.comment || '',
        });
        this.formService.patchReservationRequestFormGroup({
          lounge: 0,
          internComment: lounge.reservation?.internComment || '',
          message: lounge.reservation?.message || '',
        });
      }),
      exhaustMap(({ lounge }) =>
        this.packagesService.getAvailablePackagesForLounge(lounge!.id!).pipe(
          map((packages) => {
            return EditLoungeContainerActions.packagesLoaded({ packages });
          }),
        ),
      ),
    ),
  );
  editLounge$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditLoungeContainerActions.editLoungeClicked),
      withLatestFrom(
        this.editStore.select(editLoungeSelectors.selectLounge),
        this.editStore.select(editLoungeSelectors.selectSelectedPackages),
      ),
      switchMap(([_, lounge, selectedPackages]) => {
        const loungeForm = this.formService.getEditLoungeFormGroup();
        loungeForm.markAllAsTouched();

        if (loungeForm.valid) {
          return this.loungeService
            .updateLounge({
              id: lounge.id!,
              name: this.formService.getEditLoungeFormGroup().controls.name
                .value!,
              numberSeats:
                this.formService.getEditLoungeFormGroup().controls.numberSeats
                  .value!,
              comment:
                this.formService.getEditLoungeFormGroup().controls.comment
                  .value!,
              loungeType: {
                id: this.formService.getEditLoungeFormGroup().controls
                  .loungeType.value!,
                name: '',
              },
              reservation: null,
              event: lounge.event!,
              loungeStatus: lounge.loungeStatus,
              packages: selectedPackages,
              location: null,
            })
            .pipe(
              map(() => EditLoungeEffectsActions.editLoungeSuccessful()),
              catchError((_error) => [
                EditLoungeEffectsActions.editLoungeUnsuccessful(),
              ]),
            );
        } else {
          return of(EditLoungeEffectsActions.editLoungeFormInvalid());
        }
      }),
    ),
  );
  saveReservation$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditLoungeContainerActions.saveReservationClicked),
      withLatestFrom(
        this.editStore.select(editLoungeSelectors.selectLounge),
        this.orbitStore.select(orbitSelectors.selectEventInformation),
      ),
      switchMap(([_, lounge, event]) => {
        this.formService.getReservationRequestFormGroup().patchValue({
          internComment:
            this.formService.getReservationRequestFormGroup().controls
              .internComment.value!,
          lounge: lounge.id!,
          message:
            this.formService.getReservationRequestFormGroup().controls.message
              .value!,
        });
        this.formService.getReservationFormGroup().markAllAsTouched();
        if (this.formService.getReservationFormGroup().valid) {
          if (
            this.formService.getReservationFormGroup().controls.numberPersons
              .value! > lounge.numberSeats
          ) {
            return of(EditLoungeEffectsActions.guestsOverLoungeCapacity());
          } else {
            return this.reservationService
              .createReservation({
                name: this.formService.getReservationFormGroup().controls.name
                  .value!,
                email:
                  this.formService.getReservationFormGroup().controls.email
                    .value!,
                telephone:
                  this.formService.getReservationFormGroup().controls.telephone
                    .value!,
                numberPersons:
                  this.formService.getReservationFormGroup().controls
                    .numberPersons.value!,
                arrival:
                  this.formService.getReservationFormGroup().controls.arrival
                    .value!,
                comment:
                  this.formService.getReservationFormGroup().controls.comment
                    .value!,
                internComment:
                  this.formService.getReservationRequestFormGroup().controls
                    .internComment.value!,
                message:
                  this.formService.getReservationRequestFormGroup().controls
                    .message.value!,
                lounge: lounge,
                event: lounge.event || event,
                reservationStatus: 'assigned',
              })
              .pipe(
                map(() => EditLoungeEffectsActions.saveReservationSuccessful()),
                catchError((_error) => [
                  EditLoungeEffectsActions.saveReservationUnsuccessful(),
                ]),
              );
          }
        } else {
          return of(EditLoungeEffectsActions.saveReservationUnsuccessful());
        }
      }),
    ),
  );

  updateReservation$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditLoungeContainerActions.updateReservationClicked),
      withLatestFrom(
        this.editStore.select(editLoungeSelectors.selectLounge),
        this.orbitStore.select(orbitSelectors.selectEventInformation),
      ),
      switchMap(([action, lounge, event]) => {
        this.formService.getReservationFormGroup().markAllAsTouched();
        if (this.formService.getReservationFormGroup().valid) {
          return this.reservationService
            .updateReservation({
              id: action.id,
              name: this.formService.getReservationFormGroup().controls.name
                .value!,
              email:
                this.formService.getReservationFormGroup().controls.email
                  .value!,
              telephone:
                this.formService.getReservationFormGroup().controls.telephone
                  .value!,
              numberPersons:
                this.formService.getReservationFormGroup().controls
                  .numberPersons.value!,
              arrival:
                this.formService.getReservationFormGroup().controls.arrival
                  .value!,
              comment:
                this.formService.getReservationFormGroup().controls.comment
                  .value!,
              internComment:
                this.formService.getReservationRequestFormGroup().controls
                  .internComment.value!,
              message:
                this.formService.getReservationRequestFormGroup().controls
                  .message.value!,
              lounge: lounge,
              event: lounge.event || event,
              reservationStatus: 'assigned',
            })
            .pipe(
              map(() => EditLoungeEffectsActions.updateReservationSuccessful()),
              catchError((_error) => [
                EditLoungeEffectsActions.updateReservationUnsuccessful(),
              ]),
            );
        } else {
          return of(EditLoungeEffectsActions.saveReservationUnsuccessful());
        }
      }),
    ),
  );

  backToOpenOrbitPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditLoungeContainerActions.backClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          this.formService.getReservationFormGroup().reset();
          this.formService.getReservationRequestFormGroup().reset();
          this.location.back();
        }),
      ),
    { dispatch: false },
  );
  downloadPdf$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditLoungeContainerActions.downloadPdf),
        tap(({ reservationId }) => {
          const url = `${environment.api}/lounge/${reservationId}/pdf`;

          window.open(url, '_blank');
        }),
      ),
    { dispatch: false },
  );
  cancelReservation = createEffect(() =>
    this.actions.pipe(
      ofType(EditLoungeContainerActions.cancelReservation),
      switchMap(({ reservationId }) =>
        this.reservationService.cancelReservation(reservationId),
      ),
      map(() => EditLoungeEffectsActions.cancelReservationSuccessful()),
      catchError((_error) => [
        EditLoungeEffectsActions.cancelReservationUnsuccessful(),
      ]),
    ),
  );
  #toastService = inject(ToastService);
  saveReservationSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditLoungeEffectsActions.saveReservationSuccessful),
        withLatestFrom(this.store.select(editLoungeSelectors.selectLounge)),
        tap(() => {
          this.#toastService.showSuccess(
            'izzo.admin.orbit.reservation.create.success',
            'izzo.admin.orbit.reservation.create.success.text',
          );
        }),
      ),
    { dispatch: false },
  );

  guestsOverLoungeCapacity$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditLoungeEffectsActions.guestsOverLoungeCapacity),
        withLatestFrom(this.store.select(editLoungeSelectors.selectLounge)),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.orbit.reservation.guestsOverLoungeCapacity',
          );
        }),
      ),
    { dispatch: false },
  );

  saveReservationUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditLoungeEffectsActions.updateReservationSuccessful),
        withLatestFrom(this.store.select(editLoungeSelectors.selectLounge)),
        tap(() => {
          this.#toastService.showSuccess(
            'izzo.admin.orbit.reservation.save.success.text',
          );
        }),
      ),
    { dispatch: false },
  );

  cancelReservationSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditLoungeEffectsActions.cancelReservationSuccessful),
        withLatestFrom(this.store.select(editLoungeSelectors.selectLounge)),
        tap(() => {
          this.#toastService.showSuccess(
            'izzo.admin.orbit.reservation.cancel.success',
            'izzo.admin.orbit.reservation.cancel.success.text',
          );

          this.formService.getReservationFormGroup().reset({
            name: '',
            email: '',
            telephone: '',
            numberPersons: 0,
            arrival: '',
            comment: '',
          });

          this.formService.getReservationRequestFormGroup().reset({
            lounge: 0,
            internComment: '',
            message: '',
          });
        }),
        withLatestFrom(
          this.store,
          this.store.select(editLoungeSelectors.selectEventInformation),
        ),
        map(([_, store, event]) => {
          const callbackUrl = history.state?.['callback'];

          if (callbackUrl) {
            this.router.navigate(callbackUrl);
            return;
          }

          this.router.navigate([
            store.app.language,
            'orbit',
            'event',
            event.id,
          ]);
        }),
      ),
    { dispatch: false },
  );

  cancelReservationUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditLoungeEffectsActions.cancelReservationUnsuccessful),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.orbit.reservation.cancel.error',
            'izzo.admin.orbit.reservation.cancel.error.text',
          );
        }),
      ),
    { dispatch: false },
  );

  editSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditLoungeEffectsActions.editLoungeSuccessful),
        withLatestFrom(this.store.select(editLoungeSelectors.selectLounge)),
        tap(() => {
          this.#toastService.showSuccess(
            'izzo.admin.orbit.lounge.edit.success',
            'izzo.admin.orbit.lounge.edit.success.text',
          );

          this.formService.getEditLoungeFormGroup().reset({
            name: '',
            loungeType: 0,
            comment: '',
            numberSeats: 0,
          });
        }),
        withLatestFrom(
          this.store,
          this.store.select(editLoungeSelectors.selectEventInformation),
        ),
        map(([_, store, event]) => {
          const callbackUrl = history.state?.['callback'];

          if (callbackUrl) {
            this.router.navigate(callbackUrl);
            return;
          }

          this.router.navigate([
            store.app.language,
            'orbit',
            'event',
            event.id,
          ]);
        }),
      ),
    { dispatch: false },
  );
  editUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditLoungeEffectsActions.editLoungeUnsuccessful),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.orbit.lounge.edit.error',
            'izzo.admin.orbit.lounge.edit.error.text',
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<GlobalState>,
    private readonly orbitStore: Store<OrbitState>,
    private readonly editStore: Store<EditLoungeState>,
    private readonly formService: FormService,
    private readonly loungeService: LoungeService,
    private readonly reservationService: ReservationService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly packagesService: PackageApiService,
  ) {}
}
