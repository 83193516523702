import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Customer, Event } from '../models/app.models';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  getEventsByCustomerId(customerId: number): Observable<Event[]> {
    return this.http.get<Event[]>(
      environment.api + '/event/by-company/' + customerId,
    );
  }

  updateCustomer(customer: Customer): Observable<object> {
    return this.http.put(environment.api + '/customer', customer);
  }

  updateCustomerPassword(
    customerId: number,
    newPassword: string,
  ): Observable<object> {
    return this.http.put(environment.api + '/worker/update-password', {
      companyId: customerId,
      newPassword: newPassword,
    });
  }

  deleteCustomer(customerId: number) {
    return this.http.delete<void>(
      `${environment.api}/customer/request/${customerId}`,
    );
  }
}
