import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import {
  catchError,
  delay,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { CreateListEffectsActions } from 'src/app/features/create-list/store';
import {
  EditCancelledGuardActions,
  EditEventEffectsActions,
} from 'src/app/features/edit-event/store';
import { EditListEffectsActions } from 'src/app/features/edit-list/store';
import { MyListContainerActions } from 'src/app/features/my-list/store';
import {
  UploadCancelledGuardActions,
  UploadEventEffectsActions,
} from 'src/app/features/upload-event/store';
import { MyEventsEffectsActions } from '../../../features/my-events/store';
import { ToastService } from '../../../shared/toast/toast.service';
import { JWT_KEY } from '../../constants';
import { formatRoute, getCookieName } from '../../helpers/helpers';
import { Label } from '../../models/app.models';
import { AuthService } from '../../services/auth.service';
import { CustomerService } from '../../services/customer.service';
import { FormService } from '../../services/form.service';
import { InternalLabelService } from '../../services/internal-label.service';
import { ListService } from '../../services/list.service';
import { SubscriptionService } from '../../services/subscription.service';
import {
  ActivateTwoFactorContainerActions,
  AppComponentActions,
  AppEffectsActions,
  GuardActions,
  LabelFormContainerActions,
  LanguageGuardActions,
  LoggedInGuardActions,
  LoginContainerActions,
  MyEventsContainerActions,
  MyProfileContainerActions,
  OrbitContainerActions,
  StartEntranceGuardActions,
} from '../actions';
import { GlobalState } from '../index';
import { appSelectors } from './index';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppEffects {
  updateLanguage$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        LanguageGuardActions.languageChanged,
        AppComponentActions.languageClicked,
      ),
      switchMap((event) => this.translateService.use(event.language)),
      map(() => AppEffectsActions.languageUpdated()),
    ),
  );

  updateLanguageUrl = createEffect(
    () =>
      this.actions.pipe(
        ofType(AppEffectsActions.languageUpdated),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        map(([_, language]) => {
          if (this.router.url !== '/') {
            this.router.navigate([language, formatRoute(this.router.url)]);
          }
        }),
      ),
    { dispatch: false },
  );

  loginButtonClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoginContainerActions.loginButtonClicked),
      tap(() => {
        this.formService.getLoginFormGroup().markAllAsTouched();
      }),
      map(() => {
        const form = this.formService.getLoginFormGroup();
        if (form.valid) {
          return AppEffectsActions.loginButtonClickedIsValid({
            email: form.controls.email.value || '',
            password: form.controls.password.value || '',
          });
        } else {
          return AppEffectsActions.loginFormValidationError();
        }
      }),
    ),
  );

  loginButtonClickedIsValid$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppEffectsActions.loginButtonClickedIsValid),
      switchMap((loginFormData) =>
        this.authService
          .loginWithEmailAndPassword({
            email: loginFormData.email,
            password: loginFormData.password,
          })
          .pipe(
            map((loginResponse) => {
              if (!loginResponse?.isTwoFactorAuthenticationEnabled) {
                return AppEffectsActions.loginWasSuccessful({ loginResponse });
              } else {
                return AppEffectsActions.loginNotSuccessful();
              }
            }),
            catchError((_error) => [AppEffectsActions.loginNotSuccessful()]),
          ),
      ),
    ),
  );

  loginWasSuccessful = createEffect(() =>
    this.actions.pipe(
      ofType(
        AppEffectsActions.loginWasSuccessful,
        GuardActions.StartWebsiteLogin,
      ),
      map(({ loginResponse }) => {
        this.cookieService.set(
          getCookieName(environment.env),
          JSON.stringify(loginResponse),
          {
            path: '/',
            domain: environment.env === 'local' ? 'localhost' : '.izzo-app.com',
            expires: 1,
          },
        );
        if (loginResponse.authActive) {
          return AppEffectsActions.twoFactorActive();
        } else {
          return AppEffectsActions.twoFactorNotActive();
        }
      }),
    ),
  );

  twoFactorNotActive = createEffect(() =>
    this.actions.pipe(
      ofType(AppEffectsActions.twoFactorNotActive),
      switchMap(() => {
        const data = JSON.parse(
          this.cookieService.get(getCookieName(environment.env)),
        );

        return this.authService.generate2FACode(data);
      }),
      map((blobCode) => {
        return AppEffectsActions.twoFACodeGenerated({
          qrCode: (blobCode as string).replace(/"/g, ''),
        });
      }),
    ),
  );

  setAuthClean = createEffect(() =>
    this.actions.pipe(
      ofType(AppEffectsActions.twoAuthNotCorrect),
      delay(2000),
      map(() => AppEffectsActions.setAuthClean()),
    ),
  );

  qrCodeActivated = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          AppEffectsActions.twoFACodeGenerated,
          AppEffectsActions.twoFactorActive,
        ),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        map(([_, language]) => {
          this.router.navigate([language, 'activate-2fa']);
        }),
      ),
    { dispatch: false },
  );

  twoFactorFilled = createEffect(() =>
    this.actions.pipe(
      ofType(ActivateTwoFactorContainerActions.authFilledOut),
      switchMap(({ otpValue }) => {
        const data = JSON.parse(
          this.cookieService.get(getCookieName(environment.env)),
        );
        if (data.worker.isTwoFactorAuthenticationEnabled) {
          return this.authService
            .authWith2FA({
              ...data,
              twoFactorAuthenticationCode: otpValue,
            })
            .pipe(
              map((loginResponse) =>
                AppEffectsActions.twoAuthCorrect({ loginResponse }),
              ),
              catchError((_error) => [AppEffectsActions.twoAuthNotCorrect()]),
            );
        } else {
          return this.authService
            .turnOn2FA({
              ...data,
              twoFactorAuthenticationCode: otpValue,
            })
            .pipe(
              map((loginResponse) =>
                AppEffectsActions.twoAuthCorrect({ loginResponse }),
              ),
              catchError((_error) => [AppEffectsActions.twoAuthNotCorrect()]),
            );
        }
      }),
    ),
  );

  twoFactorCorrect = createEffect(() =>
    this.actions.pipe(
      ofType(AppEffectsActions.twoAuthCorrect),
      map(({ loginResponse }) => {
        const firstLoginData = JSON.parse(
          this.cookieService.get(getCookieName(environment.env)),
        );
        this.cookieService.set('izzo-admin-session', loginResponse.session, {
          path: '/',
          expires: 7,
        });
        this.cookieService.set(JWT_KEY, loginResponse.access_token, {
          path: '/',
          expires: 7,
        });
        this.cookieService.delete(getCookieName(environment.env), '/');

        return {
          ...loginResponse,
          ...firstLoginData,
        };
      }),
      map((data) => {
        return AppEffectsActions.twoFactorLoginComplete({
          loginResponse: data,
        });
      }),
    ),
  );

  loginDataValid$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        AppEffectsActions.twoFactorLoginComplete,
        AppEffectsActions.sessionLoginIsValid,
      ),
      map(({ loginResponse }) =>
        AppEffectsActions.customerDataObtained({
          customer: loginResponse.worker.customer,
          roles: loginResponse.worker.roles,
        }),
      ),
    ),
  );

  enteredPageUnlogged$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoggedInGuardActions.enteredPageUnlogged),
      withLatestFrom(this.store.select(appSelectors.selectLoggedIn)),
      map(([_, loggedIn]) => {
        const session = this.cookieService.get('izzo-admin-session');
        if (loggedIn) {
          return AppEffectsActions.alreadyLoggedIn();
        } else if (session) {
          return AppEffectsActions.sessionWasFound({ session });
        } else {
          return AppEffectsActions.sessionNotFound();
        }
      }),
    ),
  );

  enteredWithStart$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(StartEntranceGuardActions.enteredWithEntranceOptions),
        map(({ entranceOptions }) => {
          if (entranceOptions.sessionKey) {
            this.cookieService.set(
              'izzo-admin-session',
              entranceOptions.sessionKey,
              {
                path: '/',
                expires: 7,
              },
            );
          }
        }),
      ),
    { dispatch: false },
  );

  sessionWasFound$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppEffectsActions.sessionWasFound),
      switchMap(({ session }) => {
        return this.authService.loginWithSession(session).pipe(
          map((loginResponse) => {
            if (loginResponse?.valid) {
              return AppEffectsActions.sessionLoginIsValid({ loginResponse });
            } else {
              this.cookieService.delete('izzo-admin-session', '/');
              this.cookieService.delete(JWT_KEY, '/');
              this.cookieService.delete(getCookieName(environment.env), '/');
              return AppEffectsActions.sessionLoginNotValid();
            }
          }),
          catchError((_error) => [AppEffectsActions.sessionLoginNotValid()]),
        );
      }),
    ),
  );

  navigateToMyEventsPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          AppEffectsActions.twoAuthCorrect,
          GuardActions.StartWebsiteLoginSkipped,
          UploadEventEffectsActions.uploadEventFromStorageUnsuccessful,
          UploadCancelledGuardActions.uploadEventPaymentUnsuccessful,
          EditCancelledGuardActions.editEventPaymentUnsuccessful,
          OrbitContainerActions.backClicked,
        ),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([_, language]) => {
          this.router.navigate([language, 'my-events']);
        }),
      ),
    { dispatch: false },
  );

  newLabelClicked$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyProfileContainerActions.newLabelClicked),
        withLatestFrom(this.store.select(appSelectors.selectCustomer)),
        tap(([_, customer]) => {
          this.formService.getLabelFormGroup().reset({
            labelName: '',
            email: '',
            contactPerson: '',
            socialMedia: '',
            customerId: customer!.id,
            id: null,
          });
        }),
      ),
    { dispatch: false },
  );

  editLabelClicked$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyProfileContainerActions.editLabelClicked),
        withLatestFrom(this.store.select(appSelectors.selectCustomer)),
        map(([action, customer]) => {
          this.formService.patchLabelFormGroup({
            labelName: action.label.labelName,
            email: action.label.email,
            contactPerson: action.label.contactPerson,
            socialMedia: action.label.socialMedia,
            customerId: customer!.id,
            id: action.label.id,
          });
        }),
      ),
    { dispatch: false },
  );

  navigateToLabelFormPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          MyProfileContainerActions.editLabelClicked,
          MyProfileContainerActions.newLabelClicked,
        ),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([_, language]) => {
          this.router.navigate([`${language}/my-profile`, 'label-form']);
        }),
      ),
    { dispatch: false },
  );

  navigateToMyProfileLabelPageAfterSuccesful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AppEffectsActions.finishedLabelFormSuccessful),
        tap(() =>
          this.toastService.showSuccess(
            'izzo.admin.myProfile.labelCreate.success.title',
            'izzo.admin.myProfile.labelCreate.success.text',
          ),
        ),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([_, language]) => {
          this.router.navigate([`${language}`, 'my-profile'], {
            fragment: 'labels',
          });
        }),
      ),
    { dispatch: false },
  );

  navigateBackToMyProfileLabelPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(LabelFormContainerActions.backClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([_, language]) => {
          this.router.navigate([`${language}`, 'my-profile'], {
            fragment: 'labels',
          });
        }),
      ),
    { dispatch: false },
  );

  navigateToMyProfileSubscriptionPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEventsContainerActions.navigateToSubscriptionsClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([_, language]) => {
          this.router.navigate([`${language}`, 'my-profile'], {
            fragment: 'subscriptions',
          });
        }),
      ),
    { dispatch: false },
  );

  navigateToLoginPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          AppEffectsActions.sessionLoginNotValid,
          AppComponentActions.logoutClicked,
        ),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([_, language]) => {
          this.cookieService.delete('izzo-admin-session', '/');
          this.cookieService.delete(JWT_KEY, '/');
          this.cookieService.delete(getCookieName(environment.env), '/');
          this.router.navigate([language, 'login']);
        }),
      ),
    { dispatch: false },
  );

  navigateToUploadEventPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          AppComponentActions.uploadEventClicked,
          MyEventsContainerActions.uploadEventClicked,
        ),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([_, language]) => {
          this.router.navigate([language, 'upload-event']);
        }),
      ),
    { dispatch: false },
  );

  navigateToCreateListPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.createListClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          this.router.navigate([language, 'create-list', 'event', event.id]);
        }),
      ),
    { dispatch: false },
  );

  navigateToOpenOrbitPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEventsContainerActions.openOrbitClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          if (event.eventStatus === 'pending') {
            this.toastService.showInfo(
              'izzo.admin.event.pending.title',
              'izzo.admin.event.pending.text',
            );
          } else if (
            event.eventStatus === 'online' ||
            event.eventStatus === 'archived'
          ) {
            this.router.navigate([language, 'orbit', 'event', event.id]);
          }
        }),
      ),
    { dispatch: false },
  );

  activateOrbit$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyProfileContainerActions.activateOrbitClicked),
      withLatestFrom(
        this.store.select(appSelectors.selectCustomer),
        this.store.select(appSelectors.selectLanguage),
      ),
      switchMap(([_, company, language]) =>
        this.subscriptionService.activateOrbit(
          company!.stripeCustomerId!,
          language,
        ),
      ),
      map((paymentId) =>
        AppEffectsActions.paymentStartedSuccessful({ paymentId }),
      ),
      catchError((_error) => [AppEffectsActions.paymentStartedUnsuccessful()]),
    ),
  );

  dispatchPaymentProcess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AppEffectsActions.paymentStartedSuccessful),
        map(({ paymentId }) => {
          this.subscriptionService.startPaymentProcess(paymentId);
        }),
      ),
    { dispatch: false },
  );

  editEventUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AppEffectsActions.paymentStartedUnsuccessful),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.subscription.error.title',
            'izzo.admin.subscription.error.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  orbitCancelledUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AppEffectsActions.orbitCancelledUnsuccessfully),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.subscriptionCancel.error.title',
            'izzo.admin.subscriptionCancel.error.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  orbitCancelledSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AppEffectsActions.orbitCancelledSuccessfully),
        tap(() =>
          this.toastService.showSuccess(
            'izzo.admin.subscriptionCancel.success.title',
            'izzo.admin.subscriptionCancel.success.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  orbitReactivateUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AppEffectsActions.orbitReactivatedUnsuccessfully),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.subscriptionReactivate.error.title',
            'izzo.admin.subscriptionReactivate.error.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  orbitReactivateSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AppEffectsActions.orbitReactivatedSuccessfully),
        tap(() =>
          this.toastService.showSuccess(
            'izzo.admin.subscriptionReactivate.success.title',
            'izzo.admin.subscriptionReactivate.success.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  navigateToAllListsPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.openAllListsClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          this.router.navigate([language, 'allLists', 'event', event.id]);
        }),
      ),
    { dispatch: false },
  );

  navigateToAllInviteesPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.openAllInviteesClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          this.router.navigate([language, 'allInvitees', 'event', event.id]);
        }),
      ),
    { dispatch: false },
  );

  navigateToOpenListPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.openListClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ list }, language]) => {
          this.router.navigate([language, 'my-list', list.id]);
        }),
      ),
    { dispatch: false },
  );

  navigateToEditListPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.editListClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ list }, language]) => {
          this.router.navigate([language, 'edit-list', list.id]);
        }),
      ),
    { dispatch: false },
  );

  navigateToCreateLoungePage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.createLoungeClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          this.router.navigate([
            language,
            'create-lounge',
            'location',
            event.location.id!,
          ]);
        }),
      ),
    { dispatch: false },
  );

  navigateToEditLoungePage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.editLoungeClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ lounge }, language]) => {
          if (lounge.event) {
            this.router.navigate([
              language,
              'edit-lounge',
              lounge.id,
              lounge.event.id,
            ]);
          } else {
            this.router.navigate([language, 'edit-lounge', lounge.id]);
          }
        }),
      ),
    { dispatch: false },
  );

  customerDataObtained$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        AppEffectsActions.customerDataObtained,
        UploadEventEffectsActions.uploadEventSuccessful,
        UploadEventEffectsActions.uploadEventFromStorageSuccessful,
        EditEventEffectsActions.editEventSuccessful,
        EditEventEffectsActions.editEventFromStorageSuccessful,
        AppEffectsActions.finishedLabelFormSuccessful,
        CreateListEffectsActions.createListSuccessful,
        EditListEffectsActions.editListSuccessful,
        MyListContainerActions.addInviteeToListSuccessful,
        AppEffectsActions.orbitCancelledSuccessfully,
        AppEffectsActions.orbitReactivatedSuccessfully,
      ),
      withLatestFrom(this.store.select(appSelectors.selectCustomer)),
      switchMap(([_, customer]) =>
        forkJoin({
          internalLabel:
            this.internalLabelService.getInternalLabelsByCustomerId(
              customer!.id,
            ),
          events: this.customerService.getEventsByCustomerId(customer!.id),
          subscription: this.subscriptionService.getSubscriptionStatus(
            customer!.stripeCustomerId!,
          ),
        }),
      ),
      map((customerData) => AppEffectsActions.loggedIn({ customerData })),
    ),
  );

  reloadCustomerData = createEffect(() => {
    return this.actions.pipe(
      ofType(
        MyEventsEffectsActions.deleteEventSuccessful,
        AppEffectsActions.deleteLabelSuccessful,
      ),
      withLatestFrom(this.store.select(appSelectors.selectCustomer)),
      switchMap(([_, customer]) =>
        forkJoin({
          internalLabel:
            this.internalLabelService.getInternalLabelsByCustomerId(
              customer!.id,
            ),
          events: this.customerService.getEventsByCustomerId(customer!.id),
          subscription: this.subscriptionService.getSubscriptionStatus(
            customer!.stripeCustomerId!,
          ),
        }),
      ),
      map((customerData) =>
        AppEffectsActions.reloadCustomerDataSuccess({ customerData }),
      ),
    );
  });

  cancelOrbitSubmitClicked = createEffect(() =>
    this.actions.pipe(
      ofType(MyProfileContainerActions.cancelOrbitSubmitClicked),
      withLatestFrom(this.store.select(appSelectors.selectSubscription)),
      switchMap(([_, subscription]) => {
        return this.subscriptionService
          .cancelSubscription(subscription!.id)
          .pipe(
            map(() => AppEffectsActions.orbitCancelledSuccessfully()),
            catchError((_error) => [
              AppEffectsActions.orbitCancelledUnsuccessfully(),
            ]),
          );
      }),
    ),
  );

  reactivateOrbitSubmitClicked = createEffect(() =>
    this.actions.pipe(
      ofType(MyProfileContainerActions.reactivateOrbitSubmitClicked),
      withLatestFrom(this.store.select(appSelectors.selectSubscription)),
      switchMap(([_, subscription]) => {
        return this.subscriptionService
          .undoCancelSubscription(subscription!.id)
          .pipe(
            map(() => AppEffectsActions.orbitReactivatedSuccessfully()),
            catchError((_error) => [
              AppEffectsActions.orbitReactivatedUnsuccessfully(),
            ]),
          );
      }),
    ),
  );

  saveChangesProfileClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyProfileContainerActions.savePersonalInformationClicked),
      tap(() => {
        this.formService.getPersonalInformationFormGroup().markAllAsTouched();
      }),
      withLatestFrom(this.store.select(appSelectors.selectCustomer)),
      map(([_, customer]) => {
        const form = this.formService.getPersonalInformationFormGroup();
        if (form.valid) {
          return AppEffectsActions.savePersonalInformationClickedIsValid({
            customer: {
              id: customer!.id,
              firstname: form.controls.firstname.value || '',
              lastname: form.controls.lastname.value || '',
              companyName: form.controls.companyName.value || '',
              email: form.controls.email.value || '',
              address: customer!.address,
              place: customer!.place,
              telephone: form.controls.telephone.value || '',
            },
          });
        } else {
          return AppEffectsActions.profileFormValidationError();
        }
      }),
    ),
  );

  saveAddressClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyProfileContainerActions.saveAddressClicked),
      tap(() => {
        this.formService.getAddressFormGroup().markAllAsTouched();
      }),
      withLatestFrom(this.store.select(appSelectors.selectCustomer)),
      map(([_, customer]) => {
        const form = this.formService.getAddressFormGroup();
        if (form.valid) {
          return AppEffectsActions.saveAddressClickedIsValid({
            customer: {
              id: customer!.id,
              firstname: customer!.firstname,
              lastname: customer!.lastname,
              companyName: customer!.companyName,
              email: customer!.email,
              address: form.controls.address.value || '',
              place: form.controls.plz.value || {
                id: 0,
                plz: '',
                province: '',
              },
              telephone: customer!.telephone,
            },
          });
        } else {
          return AppEffectsActions.profileFormValidationError();
        }
      }),
    ),
  );

  navigateToEditEventPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEventsContainerActions.editEventClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([_, language]) => {
          this.router.navigate([language, 'edit-event']);
        }),
      ),
    { dispatch: false },
  );

  saveChangesProfileClickedIsValid$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        AppEffectsActions.savePersonalInformationClickedIsValid,
        AppEffectsActions.saveAddressClickedIsValid,
      ),
      switchMap(({ customer }) =>
        this.customerService.updateCustomer(customer).pipe(
          map((updateResponse) => {
            return MyProfileContainerActions.saveChangesProfileClickedWasSuccessful(
              { updateResponse },
            );
          }),
          catchError((e) => {
            this.toastService.showError(
              'izzo.admin.common.errors.title',
              e.error.message,
            );
            return of();
          }),
        ),
      ),
    ),
  );

  changePasswordClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyProfileContainerActions.changePasswordClicked),
      tap(() => {
        this.formService.getPasswordChangeFormGroup().markAllAsTouched();
      }),
      withLatestFrom(this.store.select(appSelectors.selectCustomer)),
      map(([_, customer]) => {
        const form = this.formService.getPasswordChangeFormGroup();
        if (form.valid) {
          return AppEffectsActions.changePasswordClickedIsValid({
            customerId: customer!.id,
            password: {
              newPassword: form.controls.password.value || '',
              newPasswordRepeat: form.controls.passwordRepeat.value || '',
            },
          });
        } else {
          return AppEffectsActions.changePasswordFormValidationError();
        }
      }),
    ),
  );

  changePasswordClickedIsValid$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppEffectsActions.changePasswordClickedIsValid),
      switchMap(({ customerId, password }) =>
        this.customerService.updateCustomerPassword(
          customerId,
          password.newPassword,
        ),
      ),
      map((updateResponse) => {
        this.formService.getPasswordChangeFormGroup().reset({
          password: '',
          passwordRepeat: '',
        });
        return MyProfileContainerActions.changePasswordClickedWasSuccessful({
          updateResponse,
        });
      }),
    ),
  );

  profilePageCalled$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AppEffectsActions.customerDataObtained),
        withLatestFrom(this.store.select(appSelectors.selectCustomer)),
        tap(([_, customer]) => {
          this.formService.patchEditProfileFormGroup({
            companyName: customer?.companyName || '',
            firstname: customer?.firstname || '',
            lastname: customer?.lastname || '',
            email: customer?.email || '',
            address: customer?.address || '',
            place: customer?.place!.province || '',
            telephone: customer?.telephone || '',
          });

          this.formService.patchPersonalInfoFormGroup({
            companyName: customer?.companyName || '',
            firstname: customer?.firstname || '',
            lastname: customer?.lastname || '',
            email: customer?.email || '',
            telephone: customer?.telephone || '',
          });

          this.formService.patchAddressFormGroup({
            address: customer?.address || '',
            plz: customer?.place || {
              id: 0,
              plz: '',
              province: '',
            },
          });
        }),
      ),
    { dispatch: false },
  );

  scrollToError$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          UploadEventEffectsActions.uploadEventSuccessful,
          UploadEventEffectsActions.uploadEventUnsuccessful,
          MyProfileContainerActions.saveChangesProfileClickedWasSuccessful,
          MyProfileContainerActions.changePasswordClickedWasSuccessful,
          UploadEventEffectsActions.paymentStartedUnsuccessful,
          AppEffectsActions.changePasswordFormPasswordNotMatchingError,
          EditEventEffectsActions.paymentStartedUnsuccessful,
          EditEventEffectsActions.editEventSuccessful,
          EditEventEffectsActions.editEventSuccessful,
          MyProfileContainerActions.newLabelClicked,
          MyProfileContainerActions.editLabelClicked,
          MyEventsEffectsActions.deleteEventSuccessful,
          MyEventsEffectsActions.deleteEventFailed,
          AppEffectsActions.deleteLabelSuccessful,
          AppEffectsActions.deleteLabelFailed,
          UploadEventEffectsActions.uploadEventFormInvalid,
          UploadEventEffectsActions.stepOneFormInvalid,
          UploadEventEffectsActions.stepTwoFormInvalid,
          UploadEventEffectsActions.stepFourFormInvalid,
          CreateListEffectsActions.createListSuccessful,
          CreateListEffectsActions.createListUnsuccessful,
          EditListEffectsActions.editListSuccessful,
          EditListEffectsActions.editListUnsuccessful,
          MyListContainerActions.addInviteeToListSuccessful,
          MyListContainerActions.addInviteeToListUnsuccessful,
          MyListContainerActions.deleteInviteeSuccessful,
          MyListContainerActions.deleteInviteeFailed,
        ),
        tap(() => {
          const formError = document.querySelector('.ng-invalid.ng-touched');

          if (formError) {
            formError.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }
        }),
      ),
    { dispatch: false },
  );

  deleteLabel$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyProfileContainerActions.deleteLabelSubmitClicked),
      withLatestFrom(this.store.select(appSelectors.selectDeleteLabelId)),
      switchMap(([_, labelId]) =>
        this.internalLabelService.deleteLabel(labelId!).pipe(
          map(() => AppEffectsActions.deleteLabelSuccessful()),
          catchError((error) => of(AppEffectsActions.deleteLabelFailed())),
        ),
      ),
    ),
  );

  deleteAccount$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyProfileContainerActions.deleteProfileSubmitClicked),
        withLatestFrom(this.store.select(appSelectors.selectCustomer)),
        switchMap(([_, customer]) =>
          this.customerService.deleteCustomer(customer?.id!),
        ),
        tap(() =>
          this.toastService.showSuccess(
            'izzo.admin.myProfile.deleteProfileSuccess.title',
            'izzo.admin.myProfile.deleteProfileSuccess.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  loginNotSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AppEffectsActions.loginNotSuccessful),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.login.error.title',
            'izzo.admin.login.error.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  deleteLabelSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AppEffectsActions.deleteLabelSuccessful),
        tap(() =>
          this.toastService.showSuccess(
            'izzo.admin.myProfile.labelDelete.success.title',
            'izzo.admin.myProfile.labelDelete.success.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  deleteLabelFailed$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AppEffectsActions.deleteLabelFailed),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.myProfile.labelDelete.failed.title',
            'izzo.admin.myProfile.labelDelete.failed.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  changePasswordFormPasswordNotMatchingError$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AppEffectsActions.changePasswordFormPasswordNotMatchingError),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.myProfile.error.title',
            'izzo.admin.myProfile.password.error.notMatchingText',
          ),
        ),
      ),
    { dispatch: false },
  );

  saveSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          MyProfileContainerActions.saveChangesProfileClickedWasSuccessful,
          MyProfileContainerActions.changePasswordClickedWasSuccessful,
        ),
        tap(() =>
          this.toastService.showSuccess(
            'izzo.admin.myProfile.success.title',
            'izzo.admin.myProfile.success.text',
            { dismiss: 5000 },
          ),
        ),
      ),
    { dispatch: false },
  );

  labelFormDone$ = createEffect(() =>
    this.actions.pipe(
      ofType(LabelFormContainerActions.doneClicked),
      withLatestFrom(this.store.select(appSelectors.selectCustomer)),
      tap(() => {
        this.formService.getLabelFormGroup().markAllAsTouched();
      }),
      switchMap(([_, customer]) => {
        const form = this.formService.getLabelFormGroup();
        let result$: Observable<any>;

        if (form.valid) {
          const label: Label = {
            labelName: form.controls.labelName.value!,
            contactPerson: form.controls.contactPerson.value!,
            email: form.controls.email.value!,
            socialMedia: form.controls.socialMedia.value!,
            customerId: customer?.id || undefined,
            id: form.controls.id.value!,
          };
          if (label.id) {
            result$ = this.internalLabelService
              .editLabel(label)
              .pipe(map(() => AppEffectsActions.finishedLabelFormSuccessful()));
          } else {
            result$ = this.internalLabelService
              .createLabel(label)
              .pipe(map(() => AppEffectsActions.finishedLabelFormSuccessful()));
          }
        } else {
          result$ = of(AppEffectsActions.labelFormFailed());
        }

        return result$;
      }),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<GlobalState>,
    private readonly translateService: TranslateService,
    private authService: AuthService,
    private readonly formService: FormService,
    private readonly customerService: CustomerService,
    private readonly listService: ListService,
    private readonly subscriptionService: SubscriptionService,
    private readonly internalLabelService: InternalLabelService,
    private cookieService: CookieService,
    private router: Router,
    private toastService: ToastService,
    private route: ActivatedRoute,
  ) {}
}
