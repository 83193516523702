import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GlobalState } from 'src/app/core/store';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { EmployeeService } from '../../../core/services/employee.service';
import {
  catchError,
  EMPTY,
  exhaustMap,
  filter,
  finalize,
  map,
  of,
  tap,
  withLatestFrom,
} from 'rxjs';
import { MyEmployeesContainerActions } from './actions';
import { appSelectors } from '../../../core/store/app';
import { CreateEmployeeService } from '../subpages/create/create-employee.service';
import { EditEmployeeService } from '../subpages/edit/edit-employee.service';

@Injectable({
  providedIn: 'root',
})
export class MyEmployeesEffects {
  search$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyEmployeesContainerActions.search),
      exhaustMap(({ searchText }) =>
        this.employeeService
          .searchEmployees(searchText)
          .pipe(
            map((data) => MyEmployeesContainerActions.searchSuccess({ data })),
          ),
      ),
    ),
  );

  goToCreateEmployee$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEmployeesContainerActions.createEmployee),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        map(([_, language]) => {
          this.router.navigate([language, 'my-employees', 'create']);
        }),
      ),
    { dispatch: false },
  );

  goToOverview$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEmployeesContainerActions.backClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        map(([_, language]) => {
          this.router.navigate([language, 'my-employees']);
        }),
      ),
    { dispatch: false },
  );

  goToRolemanagement$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEmployeesContainerActions.roleManagementClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        map(([_, language]) => {
          this.router.navigate([language, 'role-management']);
        }),
      ),
    { dispatch: false },
  );

  goToRolemanagementWithEmployeeId$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEmployeesContainerActions.editEmployeeRoleClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        map(([action, language]) => {
          this.router.navigate([language, 'role-management'], {
            queryParams: { search: action.employeeId },
          });
        }),
      ),
    { dispatch: false },
  );

  goToEditClient$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEmployeesContainerActions.editEmployee),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        map(([{ id }, lang]) => {
          this.router.navigate([lang, 'my-employees', 'edit', id]);
        }),
      ),
    { dispatch: false },
  );

  save$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEmployeesContainerActions.clickedCreateEmployee),
        map(() => this.formService.form),
        tap((form) => form.markAllAsTouched()),
        filter((form) => form.valid),
        map((form) => ({
          ...form.getRawValue(),
        })),
        exhaustMap((data) =>
          this.employeeService.createClient(data).pipe(
            tap(() =>
              this.toastService.showSuccess(
                'izzo.admin.myEmployees.addTitle.success',
                'izzo.admin.myEmployees.addText.success',
              ),
            ),
            withLatestFrom(this.store.select(appSelectors.selectLanguage)),
            tap(([_, lang]) => this.router.navigate([lang, 'my-employees'])),
            catchError((e) => {
              this.toastService.showError(e.error.message);
              return EMPTY;
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  //         tap(([_, form]) > form.controls.markAllAsTouched())),

  resetForm$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEmployeesContainerActions.resetCreateForm),
        tap(() => {
          this.formService.form.reset();
        }),
      ),
    { dispatch: false },
  );

  load$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEmployeesContainerActions.loadEmployee),
        exhaustMap(({ id }) =>
          this.employeeService.getEmployeeDetails(id).pipe(
            tap((data) => {
              return (
                data &&
                this.editFormService.form.reset({
                  employeeId: data.employeeId,
                  firstname: data.firstname,
                  lastname: data.lastname,
                  email: data.email,
                  telephone: data.telephone,
                  isGlobalAdmin: data.roles?.some(
                    (role) => role.name === 'GLOBAL_ADMIN',
                  ),
                })
              );
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  saveEditEmployee$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEmployeesContainerActions.saveEditEmployee),
        map(({ id }) => ({ form: this.editFormService.form, id })),
        filter(({ form }) => {
          if (form.invalid) {
            this.editFormService.form.markAllAsTouched();
          }

          return form.valid;
        }),
        tap(({ form }) => form.disable()),
        exhaustMap(({ form, id }) =>
          this.employeeService.updateEmployee(id, form.getRawValue()).pipe(
            tap(() => form.markAsPristine()),
            finalize(() => form.enable()),
            tap(() =>
              this.toastService.showSuccess(
                'izzo.admin.shared.success.changesSaved.title',
                'izzo.admin.shared.success.changesSaved.message',
              ),
            ),
            catchError(() => {
              this.toastService.showError(
                'izzo.admin.shared.errors.save.title',
                'izzo.admin.shared.errors.save.message',
              );
              return EMPTY;
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  deleteEmployee$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyEmployeesContainerActions.deleteEmployee),
      exhaustMap(({ id }) =>
        this.employeeService.deleteClient(id).pipe(
          map(() => MyEmployeesContainerActions.search({ searchText: '' })),
          tap(() =>
            this.toastService.showSuccess(
              'izzo.admin.myEmployees.deleteSuccess',
            ),
          ),
          catchError(() => {
            this.toastService.showError('izzo.admin.myEmployees.deleteError');
            return of(MyEmployeesContainerActions.search({ searchText: '' }));
          }),
        ),
      ),
    ),
  );

  resetPassword$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEmployeesContainerActions.passwortResetClicked),
        exhaustMap(({ id }) =>
          this.employeeService.resetPassword(id).pipe(
            tap(() =>
              this.toastService.showSuccess(
                'izzo.admin.myEmployees.resetPasswordSuccessTitle',
                'izzo.admin.myEmployees.resetPasswordSuccessText',
              ),
            ),
            catchError(() => {
              this.toastService.showError(
                'izzo.admin.myEmployees.resetPasswordError',
              );
              return EMPTY;
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<GlobalState>,
    private readonly router: Router,
    private readonly employeeService: EmployeeService,
    private readonly toastService: ToastService,
    private readonly formService: CreateEmployeeService,
    private readonly editFormService: EditEmployeeService,
  ) {}
}
