import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { ReservationService } from 'src/app/core/services/reservation.service';
import { appSelectors } from 'src/app/core/store/app';
import { ToastService } from 'src/app/shared/toast/toast.service';
import {
  ReservationContainerActions,
  ReservationEffectsActions,
  reservationSelectors,
} from '.';
import { FormService } from '../../../core/services/form.service';
import { GlobalState } from '../../../core/store';
import { OrbitState } from '../../orbit/store/orbit.state';

@Injectable({
  providedIn: 'root',
})
export class ReservationEffects {
  #toastService = inject(ToastService);
  #actions = inject(Actions);
  #translate = inject(TranslateService);

  cancelReservationClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(ReservationContainerActions.cancelReservationClicked),
      switchMap(({ reservation }) =>
        this.reservationService.cancelReservation(reservation.id!),
      ),
      map(() => ReservationEffectsActions.cancelReservationSuccessful()),
      catchError((_error) => [
        ReservationEffectsActions.cancelReservationUnsuccessful(),
      ]),
    ),
  );

  assignSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ReservationEffectsActions.assignReservationSuccessful),
        tap(() => {
          this.#toastService.showSuccess(
            'izzo.admin.orbit.reservation.assign.success',
            'izzo.admin.orbit.reservation.assign.success.text',
          );

          this.formService.getReservationRequestFormGroup().reset({
            lounge: 0,
            internComment: '',
            message: '',
          });
        }),
        withLatestFrom(
          this.store,
          this.store.select(reservationSelectors.selectEventInformation),
        ),
        map(([_, store, event]) =>
          this.router.navigate([
            store.app.language,
            'orbit',
            'event',
            event.id,
          ]),
        ),
      ),
    { dispatch: false },
  );

  cancelSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ReservationEffectsActions.cancelReservationSuccessful),
        tap(() => {
          this.#toastService.showSuccess(
            'izzo.admin.orbit.reservation.cancel.success',
            'izzo.admin.orbit.reservation.cancel.success.text',
          );

          this.formService.getReservationRequestFormGroup().reset({
            lounge: 0,
            internComment: '',
            message: '',
          });
        }),
        withLatestFrom(
          this.store,
          this.store.select(reservationSelectors.selectEventInformation),
        ),
        map(([_, store, event]) =>
          this.router.navigate([
            store.app.language,
            'orbit',
            'event',
            event.id,
          ]),
        ),
      ),
    { dispatch: false },
  );

  backToOpenOrbitPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ReservationContainerActions.backClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          this.router.navigate([language, 'orbit', 'event', event.id]);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<GlobalState>,
    private readonly orbitStore: Store<OrbitState>,
    private readonly formService: FormService,
    private readonly router: Router,
    private readonly reservationService: ReservationService,
  ) {}
}
