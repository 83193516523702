import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EventService } from '../../../core/services/event.service';
import { FormService } from '../../../core/services/form.service';
import { GlobalState } from '../../../core/store';

import { addDays, format } from 'date-fns';
import {
  catchError,
  map,
  Observable,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  makeDateValid,
  toggleValueInArray,
  toggleValueInArrayWithLimit,
} from '../../../core/helpers/helpers';
import { UploadEditEvent } from '../../../core/models/app.models';
import { EditEventFormService } from '../../../core/services/edit-event-form.service';
import { PaymentService } from '../../../core/services/payment.service';
import { MyEventsContainerActions } from '../../../core/store/actions';
import { appSelectors } from '../../../core/store/app';
import { ToastService } from '../../../shared/toast/toast.service';
import { editEventCustomSelectors } from './edit-event.selectors';
import {
  EditCancelledGuardActions,
  EditEventContainerActions,
  EditEventEffectsActions,
  editEventSelectors,
  EditSuccessfulGuardActions,
} from './index';

@Injectable({
  providedIn: 'root',
})
export class EditEventEffects {
  patchEditValues$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEventsContainerActions.editEventClicked),
        withLatestFrom(
          this.store.select(editEventSelectors.selectEventInformation),
          this.store.select(editEventCustomSelectors.selectSelectedProducts),
        ),
        tap(([_, eventInformation, selectedProducts]) => {
          this.eventFormService.patchEventEditForms(
            eventInformation,
            selectedProducts,
          );
        }),
      ),
    { dispatch: false },
  );
  nextButtonStepOneClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditEventContainerActions.nextButtonStepOneClicked),
      tap(() => {
        this.eventFormService
          .getEditEventInformationFormGroup()
          .markAllAsTouched();
      }),
      map(() => {
        if (this.eventFormService.getEditEventInformationFormGroup().valid) {
          return EditEventEffectsActions.stepOneFormValid();
        }
        return EditEventEffectsActions.stepOneFormInvalid();
      }),
    ),
  );
  nextButtonStepTwoClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditEventContainerActions.nextButtonStepTwoClicked),
      tap(() => {
        this.eventFormService
          .getEditEventGenreTagsFormGroup()
          .markAllAsTouched();
      }),
      map(() => {
        if (this.eventFormService.getEditEventGenreTagsFormGroup().valid) {
          return EditEventEffectsActions.stepTwoFormValid();
        }
        return EditEventEffectsActions.stepTwoFormInvalid();
      }),
    ),
  );
  nextButtonStepThreeClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditEventContainerActions.nextButtonStepThreeClicked),
      tap(() => {
        this.eventFormService
          .getEditEventTicketingFormGroup()
          .markAllAsTouched();
      }),
      map(() => {
        if (
          this.eventFormService.getEditEventTicketingFormGroup().valid ||
          this.eventFormService.getEditEventTicketingFormGroup().disabled
        ) {
          return EditEventEffectsActions.stepThreeFormValid();
        }
        return EditEventEffectsActions.stepThreeFormInvalid();
      }),
    ),
  );
  nextButtonStepFourClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditEventContainerActions.nextButtonStepFourClicked),
      tap(() => {
        this.eventFormService
          .getEditEventBoostersFormGroup()
          .markAllAsTouched();
      }),
      map(() => {
        if (this.eventFormService.getEditEventBoostersFormGroup().valid) {
          return EditEventEffectsActions.stepFourFormValid();
        }
        return EditEventEffectsActions.stepFourFormInvalid();
      }),
    ),
  );
  uploadEventSuccessfulWithPayment$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditEventEffectsActions.editEventSuccessfulWithPayment),
      withLatestFrom(
        this.store.select(appSelectors.selectCustomer),
        this.store.select(editEventCustomSelectors.selectSelectedProducts),
        this.store.select(appSelectors.selectLanguage),
      ),
      switchMap(([_, customer, selectedProducts, language]) =>
        this.paymentService.createEditPayment(
          customer!.stripeCustomerId!,
          selectedProducts,
          language,
        ),
      ),
      map((paymentId) =>
        EditEventEffectsActions.paymentStartedSuccessful({ paymentId }),
      ),
      catchError((_error) => [
        EditEventEffectsActions.paymentStartedUnsuccessful(),
      ]),
    ),
  );
  dispatchPaymentProcess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditEventEffectsActions.paymentStartedSuccessful),
        map(({ paymentId }) => {
          this.paymentService.startPaymentProcess(paymentId);
        }),
      ),
    { dispatch: false },
  );
  uploadEvent$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditEventEffectsActions.editEventStarted),
      withLatestFrom(this.store),
      switchMap(([_, state]) => this.handleUploadEvent(state)),
    ),
  );
  uploadEventFromStorage$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditSuccessfulGuardActions.editEventPaymentSuccessful),
      switchMap(() => {
        return this.eventService
          .editEvent(JSON.parse(localStorage.getItem('editEvent')!))
          .pipe(
            map(() => EditEventEffectsActions.editEventFromStorageSuccessful()),
            catchError((_error) => [
              EditEventEffectsActions.editEventFromStorageUnsuccessful(),
            ]),
          );
      }),
    ),
  );
  uploadClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditEventContainerActions.uploadClicked),
      map(() => {
        return this.eventFormService.validateUploadForm();
      }),
      map((invalidSteps) => {
        if (invalidSteps.length > 0) {
          return EditEventEffectsActions.editEventFormInvalid({ invalidSteps });
        }
        return EditEventEffectsActions.formStepsValid();
      }),
    ),
  );

  editEventPaymentUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          EditCancelledGuardActions.editEventPaymentUnsuccessful,
          EditEventEffectsActions.editEventUnsuccessful,
        ),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.editStorageCancelled.error.title',
            'izzo.admin.editStorageCancelled.error.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  editEventFromStorageSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          EditEventEffectsActions.editEventFromStorageSuccessful,
          EditEventEffectsActions.editEventSuccessful,
        ),
        tap(() =>
          this.toastService.showSuccess(
            'izzo.admin.editEvent.success.title',
            'izzo.admin.editEvent.success.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  editEventUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          EditEventEffectsActions.editEventUnsuccessful,
          EditEventEffectsActions.paymentStartedUnsuccessful,
        ),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.uploadEvent.error.title',
            'izzo.admin.uploadEvent.error.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  editEventFormInvalid$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditEventEffectsActions.editEventFormInvalid),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.uploadEvent.form.error.title',
            'izzo.admin.uploadEvent.form.error.text',
          ),
        ),
      ),
    { dispatch: false },
  );
  deleteNewEventFromStorage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          EditEventEffectsActions.editEventFromStorageSuccessful,
          EditEventEffectsActions.editEventFromStorageUnsuccessful,
        ),
        tap(() => localStorage.removeItem('editEvent')),
      ),
    { dispatch: false },
  );
  uploadEventPostponed$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditEventEffectsActions.editEventPostponed),
      withLatestFrom(this.store),
      tap(([_, state]) =>
        localStorage.setItem(
          'editEvent',
          JSON.stringify({
            id: state.editEvent.eventInformation.id!,
            name: state.editEvent.eventInformation.name!,
            startDate: makeDateValid(
              state.editEvent.eventInformation.startDate!,
            ),
            endDate: makeDateValid(state.editEvent.eventInformation.endDate!),
            startTime: state.editEvent.eventInformation.startTime!,
            endTime: state.editEvent.eventInformation.endTime!,
            genres: state.editEvent.selectedGenres,
            tags: state.editEvent.selectedTags,
            location: { id: state.editEvent.eventInformation.location! },
            internalLabel: state.editEvent.eventInformation.internalLabel,
            locationName: state.editEvent.eventInformation.locationName,
            locationStreet: state.editEvent.eventInformation.locationStreet,
            locationPlz: state.editEvent.eventInformation.locationPlz,
            locationProvince: state.editEvent.eventInformation.locationProvince,
            ageRestrictionMen:
              state.editEvent.eventInformation.ageRestrictionMen,
            ageRestrictionWomen:
              state.editEvent.eventInformation.ageRestrictionWomen,
            fromPrice:
              state.editEvent.eventInformation.fromPrice === '0.00'
                ? ''
                : state.editEvent.eventInformation.fromPrice ?? '',
            ticketLink: state.editEvent.eventInformation.ticketLink ?? '',
            fyraTicketLink:
              state.editEvent.eventInformation.fyraTicketLink ?? '',
            pressText: state.editEvent.eventInformation.pressText!,
            pinActive: state.editEvent.products['pin'].selected,
            flyerActive: state.editEvent.products['flyer'].selected,
            highlightActive: state.editEvent.products['mark'].selected,
            flyer: state.editEvent.eventInformation.flyer,
          }),
        ),
      ),
      map(() => EditEventEffectsActions.editEventSuccessfulWithPayment()),
    ),
  );
  eidtClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditEventEffectsActions.formStepsValid),
      withLatestFrom(
        this.store.select(editEventCustomSelectors.selectIsAnyProductSelected),
      ),
      map(([_, anyProductSelected]) => {
        if (anyProductSelected) {
          return EditEventEffectsActions.editEventPostponed();
        }
        return EditEventEffectsActions.editEventStarted();
      }),
    ),
  );
  editSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditEventEffectsActions.editEventSuccessful),
        withLatestFrom(this.store),
        tap(() => {
          this.eventFormService.getEditEventInformationFormGroup().reset({
            name: '',
            startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
            endDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
            startTime: '',
            endTime: '',
            location: 0,
            locationName: '',
            locationStreet: '',
            locationPlz: '',
            locationProvince: '',
            ageRestrictionWomen: '',
            ageRestrictionMen: '',
            pressText: '',
          });

          this.eventFormService.getEditEventGenreTagsFormGroup().reset({
            genres: [],
            tags: [],
          });

          this.eventFormService.getEditEventTicketingFormGroup().reset({
            fromPrice: '',
            ticketLink: '',
            fyraTicketLink: '',
          });

          this.eventFormService.getEditEventBoostersFormGroup().reset({
            flyerUploaded: false,
            boosters: [],
          });
        }),
        map(([_, store]) =>
          this.router.navigate([store.app.language, 'my-events']),
        ),
      ),
    { dispatch: false },
  );
  toggledGenre$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditEventContainerActions.toggleGenreClicked),
        map(({ genreId }) =>
          this.eventFormService
            .getEditEventGenreTagsFormGroup()
            .controls.genres.setValue(
              toggleValueInArrayWithLimit(
                this.eventFormService.getEditEventGenreTagsFormGroup().controls
                  .genres.value!,
                genreId,
                3,
              ),
            ),
        ),
      ),
    { dispatch: false },
  );
  toggledTag$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditEventContainerActions.toggleTagClicked),
        map(({ tagId }) =>
          this.eventFormService
            .getEditEventGenreTagsFormGroup()
            .controls.tags.setValue(
              toggleValueInArrayWithLimit(
                this.eventFormService.getEditEventGenreTagsFormGroup().controls
                  .tags.value!,
                tagId,
                3,
              ),
            ),
        ),
      ),
    { dispatch: false },
  );
  selectedProduct$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditEventContainerActions.productSelectionToggled),
        map(({ product }) => {
          this.eventFormService
            .getEditEventBoostersFormGroup()
            .controls.boosters.setValue(
              toggleValueInArray(
                this.eventFormService.getEditEventBoostersFormGroup().controls
                  .boosters.value!,
                product.key,
              ),
            );
        }),
      ),
    { dispatch: false },
  );
  flyerCropped$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditEventContainerActions.flyerCropped),
        map(({ flyer }) => {
          this.eventFormService
            .getEditEventBoostersFormGroup()
            .controls.flyerUploaded.setValue(!!flyer);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<GlobalState>,
    private readonly formService: FormService,
    private readonly router: Router,
    private readonly eventService: EventService,
    private readonly paymentService: PaymentService,
    private readonly eventFormService: EditEventFormService,
    private readonly toastService: ToastService,
  ) {}

  private handleUploadEvent(state: GlobalState): Observable<Action> {
    const eventInfo = state.editEvent.eventInformation;
    const location = eventInfo.location ?? '';

    const eventPayload: UploadEditEvent = {
      id: eventInfo.id!,
      name: eventInfo.name!,
      startDate: makeDateValid(eventInfo.startDate!),
      endDate: makeDateValid(eventInfo.endDate!),
      startTime: eventInfo.startTime!,
      endTime: eventInfo.endTime!,
      internalLabel: eventInfo.internalLabel!,
      location: { id: location },
      locationName: eventInfo.locationName ?? '',
      locationStreet: eventInfo.locationStreet ?? '',
      locationPlz: eventInfo.locationPlz ?? '',
      locationProvince: eventInfo.locationProvince ?? '',
      genres: state.editEvent.selectedGenres,
      tags: state.editEvent.selectedTags,
      ageRestrictionMen: eventInfo.ageRestrictionMen!,
      ageRestrictionWomen: eventInfo.ageRestrictionWomen!,
      fromPrice:
        eventInfo.fromPrice === '0.00' ? '' : eventInfo.fromPrice ?? '',
      ticketLink: eventInfo.ticketLink ?? '',
      fyraTicketLink: eventInfo.fyraTicketLink ?? '',
      pressText: eventInfo.pressText!,
      pinActive: state.editEvent.products.pin.selected,
      highlightActive: state.editEvent.products.mark.selected,
      flyerActive: state.editEvent.products.flyer.selected,
      eventId: eventInfo.eventId,
      flyer: eventInfo.flyer,
    };

    return this.eventService.editEvent(eventPayload).pipe(
      map(() => EditEventEffectsActions.editEventSuccessful()),
      catchError(() => [EditEventEffectsActions.editEventUnsuccessful()]),
    );
  }
}
