import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../core/store';
import { FormService } from '../../../core/services/form.service';
import { Router } from '@angular/router';
import { EventService } from '../../../core/services/event.service';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import {
  MyEventsContainerActions2,
  MyEventsEffectsActions,
  myEventsSelectors,
} from './index';

@Injectable({
  providedIn: 'root',
})
export class MyEventsEffects {
  resetFilters$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEventsContainerActions2.resetFilterButtonClicked),
        tap(() => {
          this.formService.patchFilterFormGroup({
            filterContains: '',
            dateTo: '',
            dateFrom: '',
          });
        }),
      ),
    { dispatch: false },
  );

  deleteEvent$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyEventsContainerActions2.deleteEventSubmitClicked),
      withLatestFrom(this.store.select(myEventsSelectors.selectEventDelete)),
      switchMap(([_, eventDelete]) => {
        if (
          eventDelete!.eventStatus === 'online' ||
          eventDelete!.eventStatus === 'archived'
        ) {
          return this.eventService.deleteEvent(eventDelete!.id!).pipe(
            map(() => MyEventsEffectsActions.deleteEventSuccessful()),
            catchError((error) =>
              of(MyEventsEffectsActions.deleteEventFailed()),
            ),
          );
        } else {
          return this.eventService.deleteApproveEvent(eventDelete!.id!).pipe(
            map(() => MyEventsEffectsActions.deleteEventSuccessful()),
            catchError((error) =>
              of(MyEventsEffectsActions.deleteEventFailed()),
            ),
          );
        }
      }),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<GlobalState>,
    private readonly formService: FormService,
    private readonly router: Router,
    private readonly eventService: EventService,
  ) {}
}
