import { Injectable } from '@angular/core';
import { Role, WorkerRole } from '../models/app.models';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private readonly http: HttpClient) {}

  searchWorkerRole(searchText: string) {
    return this.http.get<WorkerRole[]>(
      `${environment.api}/role?search=${searchText}`,
    );
  }

  getAllRoles() {
    return this.http.get<Role[]>(`${environment.api}/role/all`);
  }

  addRoleToEmployee(employeeRole: any) {
    return this.http.put<{ successful: boolean; message: string }>(
      `${environment.api}/role/add`,
      employeeRole,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  deleteWorkerRole(workerRole: WorkerRole) {
    return this.http.delete<void>(`${environment.api}/role/delete`, {
      body: workerRole,
    });
  }
}
