import { HttpParams } from '@angular/common/http';
import { PaginationQuery } from './pagination-query.interface';

export function createPaginationParams(query?: PaginationQuery): HttpParams {
  const httpParams = new HttpParams();

  if (!query) {
    return httpParams;
  }

  if (query.take) {
    httpParams.set('take', query.take);
  }

  if (query.skip) {
    httpParams.set('skip', query.skip);
  }

  return httpParams;
}
