import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, map, Observable, throwError} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  // @ts-ignore
  stripe = Stripe(environment.stripeKey);

  constructor(private http: HttpClient) {
  }

  createPayment(customerId: string, selectedProducts: any[], lang: string): Observable<string> {
    return this.http.post(environment.api + '/payment', {stripeCustomerId: customerId, selectedProducts, lang}).pipe(
      map((response: any) => response.id),
      catchError((error: HttpErrorResponse) => throwError(() => new Error(`Something bad happened; please try again later. Http-Response: ${error.status}`))),
    );
  }

  startPaymentProcess(sessionId: string) {
    return this.stripe.redirectToCheckout({sessionId})
  }

  createEditPayment(customerId: string, selectedProducts: any[], lang: string): Observable<string> {
    return this.http.post(environment.api + '/payment/edit', {
      stripeCustomerId: customerId,
      selectedProducts,
      lang
    }).pipe(
      map((response: any) => response.id),
      catchError((error: HttpErrorResponse) => throwError(() => new Error(`Something bad happened; please try again later. Http-Response: ${error.status}`))),
    );
  }
}
