import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { SharedModule } from '../shared.module';
import { ToastTypes } from './toast-types';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { AsyncPipe } from '@angular/common';

const AutoDismissInSeconds = 5;

@Component({
  selector: 'izzo-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  imports: [TranslateModule, SharedModule, SvgIconComponent, AsyncPipe],
})
export class ToastComponent implements OnInit {
  @Input() title = '';
  @Input() text?: string;
  @Input() type: ToastTypes = 'info';

  @Output() closeToast = new EventEmitter<void>();

  public ngOnInit(): void {
    this.startAutoDismissTimer();
  }

  private startAutoDismissTimer(): void {
    var ms = AutoDismissInSeconds * 1000;

    timer(ms).subscribe(() => this.closeToast.emit());
  }
}
