import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClubDetails } from './models/club-details.interface';
import { ClubItem } from './models/club-item.interface';

const BaseUrl = `${environment.api}/club`;
const PendingBaseUrl = `${environment.api}/pending-club`;

@Injectable({ providedIn: 'root' })
export class ClubApiService {
  #http = inject(HttpClient);

  public getClubs(): Observable<ClubItem[]> {
    return this.#http.get<ClubItem[]>(`${BaseUrl}`);
  }

  public getClubDetails(
    id: number,
    status: string,
  ): Observable<ClubDetails | null> {
    return this.#http.get<ClubDetails>(
      `${status === 'pending' || status === 'denied' ? PendingBaseUrl : BaseUrl}/${id}`,
    );
  }

  public createClub(
    data: Omit<ClubDetails, 'id' | 'packages' | 'lounges'>,
  ): Observable<number> {
    return this.#http.post<number>(PendingBaseUrl, data);
  }

  public updateClub(
    id: number,
    status: string,
    data: Omit<ClubDetails, 'id' | 'packages' | 'lounges'>,
  ) {
    return this.#http.put<void>(
      `${PendingBaseUrl}/${id}`,
      { ...data, status },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public deleteClub(id: number, status: string): Observable<void> {
    return this.#http.delete<void>(
      `${status === 'pending' || status === 'denied' ? PendingBaseUrl : BaseUrl}/${id}`,
    );
  }
}
