import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  editWasCancelled,
  editWasSuccessful,
  enterWithStart,
  enterWithStartSkip,
  hasEditableEvent,
  isAuthActive,
  isLoggedIn,
  isLoggedOut,
  setLanguage,
  uploadWasCancelled,
  uploadWasSuccessful,
} from './core/guards';
import { getReadonlyList } from './core/resolvers';

const routes: Routes = [
  {
    path: 'start',
    loadChildren: () =>
      import('./features/my-events/my-events.module').then(
        (m) => m.MyEventsModule,
      ),
    canActivate: [enterWithStart],
  },
  {
    path: 'start-skip',
    loadChildren: () =>
      import('./features/my-events/my-events.module').then(
        (m) => m.MyEventsModule,
      ),
    canActivate: [enterWithStartSkip],
  },
  {
    path: ':lang',
    canActivate: [setLanguage],
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./features/login/login.module').then((m) => m.LoginModule),
        canActivate: [isLoggedOut],
      },
      {
        path: 'my-events',
        loadChildren: () =>
          import('./features/my-events/my-events.module').then(
            (m) => m.MyEventsModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'my-profile',
        loadChildren: () =>
          import('./features/my-profile/my-profile.module').then(
            (m) => m.MyProfileModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'edit-event',
        loadChildren: () =>
          import('./features/edit-event/edit-event.module').then(
            (m) => m.EditEventModule,
          ),
        canActivate: [isLoggedIn, hasEditableEvent],
      },
      {
        path: 'upload-event',
        loadChildren: () =>
          import('./features/upload-event/upload-event.module').then(
            (m) => m.UploadEventModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'success-new',
        loadChildren: () =>
          import('./features/my-events/my-events.module').then(
            (m) => m.MyEventsModule,
          ),
        canActivate: [isLoggedIn, uploadWasSuccessful],
      },
      {
        path: 'cancel-new',
        loadChildren: () =>
          import('./features/my-events/my-events.module').then(
            (m) => m.MyEventsModule,
          ),
        canActivate: [isLoggedIn, uploadWasCancelled],
      },
      {
        path: 'success-edit',
        loadChildren: () =>
          import('./features/my-events/my-events.module').then(
            (m) => m.MyEventsModule,
          ),
        canActivate: [isLoggedIn, editWasSuccessful],
      },
      {
        path: 'cancel-edit',
        loadChildren: () =>
          import('./features/my-events/my-events.module').then(
            (m) => m.MyEventsModule,
          ),
        canActivate: [isLoggedIn, editWasCancelled],
      },
      {
        path: 'activate-2fa',
        loadChildren: () =>
          import(
            './features/activate-two-factor/activate-two-factor.module'
          ).then((m) => m.ActivateTwoFactorModule),
        canActivate: [isAuthActive],
      },
      {
        path: 'crm',
        loadChildren: () =>
          import('./features/crm/crm-routing.module').then(
            (m) => m.CrmRoutingModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'create-list',
        loadChildren: () =>
          import('./features/create-list/create-list.module').then(
            (m) => m.CreateListModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'edit-list',
        loadChildren: () =>
          import('./features/edit-list/edit-list.module').then(
            (m) => m.EditListModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'my-list',
        loadChildren: () =>
          import('./features/my-list/my-list.module').then(
            (m) => m.MyListModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'package',
        loadChildren: () =>
          import('./features/package/package-routing.module').then(
            (m) => m.PackageRoutingModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'allLists',
        loadChildren: () =>
          import(
            './features/my-list/overview/allLists-overview/allLists-overview.module'
          ).then((m) => m.AllListsOverviewModule),
        canActivate: [isLoggedIn],
      },
      {
        path: 'allInvitees',
        loadChildren: () =>
          import(
            './features/my-list/overview/allInvitees-overview/allInvitees-overview.module'
          ).then((m) => m.AllInviteesOverviewModule),
        canActivate: [isLoggedIn],
      },
      {
        path: 'list-readOnly',
        loadChildren: () =>
          import('./features/list-readOnly/list-readOnly.module').then(
            (m) => m.ListReadOnlyModule,
          ),
        resolve: {
          listReadOnly: getReadonlyList,
        },
      },
      {
        path: 'orbit',
        loadChildren: () =>
          import('./features/orbit/orbit.module').then((m) => m.OrbitModule),
        canActivate: [isLoggedIn],
      },
      {
        path: 'club',
        loadChildren: () =>
          import('./features/club/club-routing.module').then(
            (m) => m.ClubRoutingModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'my-employees',
        loadChildren: () =>
          import('./features/my-employees/my-employees.module').then(
            (m) => m.MyEmployeesModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'role-management',
        loadChildren: () =>
          import('./features/role-management/role-management.module').then(
            (m) => m.RoleManagementModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'create-lounge',
        loadChildren: () =>
          import('./features/create-lounge/create-lounge.module').then(
            (m) => m.CreateLoungeModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'edit-lounge',
        loadChildren: () =>
          import('./features/edit-lounge/edit-lounge.module').then(
            (m) => m.EditLoungeModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'reservation',
        loadChildren: () =>
          import('./features/reservation/reservation.module').then(
            (m) => m.ReservationtModule,
          ),
        canActivate: [isLoggedIn],
      },
      {
        path: 'allReservations',
        loadChildren: () =>
          import(
            './features/reservation/overview/allReservations-overview/allReservations-overview.module'
          ).then((m) => m.AllReservationsOverviewModule),
        canActivate: [isLoggedIn],
      },
      {
        path: 'allLounges',
        loadChildren: () =>
          import(
            './features/edit-lounge/overview/allLounges-overview/allLounges-overview.module'
          ).then((m) => m.AllLoungesOverviewModule),
        canActivate: [isLoggedIn],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/de/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
