import { Injectable, inject } from '@angular/core';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { BrokenBusinessRuleException } from './broken-business-rule.exception';

@Injectable({ providedIn: 'root' })
export class ErrorHandlingService {
  #toastService = inject(ToastService);

  public handleError(error: Error, translationBaseKey: string) {
    if (error instanceof BrokenBusinessRuleException) {
      const translationKey = `${translationBaseKey}.${error.code}`;
      const title = `${translationKey}.title`;
      const text = `${translationKey}.message`;

      this.#toastService.showError(title, text);
      return;
    }

    const { title, text } = this.getErrorTranslationKey(error);

    this.#toastService.showError(title, text);
  }

  private getErrorTranslationKey(error: Error): {
    title: string;
    text?: string;
  } {
    // We should handle any common errors here

    return {
      title: 'izzo.admin.common.errors.unknown.title',
      text: 'izzo.admin.common.errors.unknown.message',
    };
  }
}
