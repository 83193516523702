import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { BrokenBusinessRuleException } from './broken-business-rule.exception';

@Injectable()
export class BrokenBusinessRuleInterceptor implements HttpInterceptor {
  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error?.type === 'broken-business-rule') {
          return throwError(
            () =>
              new BrokenBusinessRuleException(
                error.error.message,
                error.error.errorCode,
              ),
          );
        }

        return throwError(() => error);
      }),
    );
  }
}
