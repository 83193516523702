import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createPaginationParams } from 'src/app/core/pagination/create-pagination-params.function';
import { PaginationQuery } from 'src/app/core/pagination/pagination-query.interface';
import { Pagination } from 'src/app/core/pagination/pagination.interface';
import { environment } from 'src/environments/environment';
import { PackageDetails } from './models/package-details.interface';

const BaseUrl = `${environment.api}/event-package`;

@Injectable({ providedIn: 'root' })
export class PackageApiService {
  #http = inject(HttpClient);

  public getPackages(
    pagination?: PaginationQuery,
  ): Observable<Pagination<PackageDetails>> {
    const params = createPaginationParams(pagination);

    return this.#http.get<Pagination<PackageDetails>>(`${BaseUrl}`, { params });
  }

  public getAvailablePackagesForLounge(
    loungeId: number,
  ): Observable<PackageDetails[]> {
    return this.#http.get<PackageDetails[]>(`${BaseUrl}/lounge/${loungeId}`);
  }

  public getPackageDetails(id: number): Observable<PackageDetails | null> {
    return this.#http.get<PackageDetails>(`${BaseUrl}/${id}`);
  }

  public createPackage(data: Omit<PackageDetails, 'id'>): Observable<number> {
    return this.#http.post<number>(BaseUrl, data);
  }

  public updatePackage(
    id: number,
    data: Omit<PackageDetails, 'id'>,
  ): Observable<void> {
    return this.#http.put<void>(`${BaseUrl}/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public deletePackage(id: number): Observable<void> {
    return this.#http.delete<void>(`${BaseUrl}/${id}`);
  }
}
