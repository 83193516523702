import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientDetails } from './models/client-details.interface';
import { ClientGridItem } from './models/client-grid-item.interface';
import { CreateClient } from './models/create-client.type';
import { ImportResult } from './models/import-result.interface';

const BaseUrl = `${environment.api}/company-client`;

@Injectable({ providedIn: 'root' })
export class CrmService {
  constructor(private readonly http: HttpClient) {}

  public searchClients(searchText: string): Observable<ClientGridItem[]> {
    return this.http.get<ClientGridItem[]>(`${BaseUrl}/?search=${searchText}`);
  }

  public getClientDetails(id: number): Observable<ClientDetails | null> {
    return this.http.get<ClientDetails>(`${BaseUrl}/${id}`);
  }

  public createClient(client: CreateClient): Observable<number> {
    return this.http.post<number>(BaseUrl, client);
  }

  public updateClient(id: number, client: ClientDetails): Observable<void> {
    return this.http.put<void>(`${BaseUrl}/${id}`, client, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public deleteClient(id: number): Observable<void> {
    return this.http.delete<void>(`${BaseUrl}/${id}`);
  }

  public importCsv(file: Blob): Observable<ImportResult> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<ImportResult>(`${BaseUrl}/import/csv`, formData);
  }

  public exportCsv(): Observable<HttpResponse<Blob>> {
    return this.http.get(`${BaseUrl}/export/csv`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  getCsvTemplate(): Observable<HttpResponse<Blob>> {
    return this.http.get(`${BaseUrl}/template/csv`, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
