import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastComponent } from '../toast.component';
import { Toast } from '../toast.type';
import { fadeAnimation } from './toast-container.animation';
import { AsyncPipe } from '@angular/common';

const ToastLimit = 2;

@Component({
  selector: 'izzo-toast-container',
  templateUrl: 'toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ToastComponent, AsyncPipe],
  standalone: true,
  animations: [fadeAnimation],
})
export class ToastContainerComponent {
  public readonly toasts = new BehaviorSubject<Toast[]>([]);

  public show(toast: Toast): void {
    this.dismissPastToasts();
    this.toasts.next([toast, ...this.toasts.value]);

    if (toast.options?.dismiss) {
      setTimeout(() => this.dismiss(toast.id), toast.options.dismiss);
    }
  }

  public trackByFunc(_: number, item?: Toast) {
    return item ? item.id : undefined;
  }

  public dismiss(id: symbol): void {
    this.toasts.next(this.toasts.value.filter((x) => x.id !== id));
  }

  private dismissPastToasts(): void {
    const toasts = this.toasts.value.map((x) => x.id);
    const toDelete: symbol[] = [];

    if (toasts.length > ToastLimit) {
      toDelete.push(...toasts.splice(0, toasts.length - ToastLimit));
    }

    if (toasts.length === ToastLimit) {
      const oldestToast = toasts.pop();
      oldestToast && toDelete.push(oldestToast);
    }

    this.toasts.next(this.toasts.value.filter((x) => !toDelete.includes(x.id)));
  }
}
