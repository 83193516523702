import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { addDays, format } from 'date-fns';
import {
  catchError,
  map,
  Observable,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  makeDateValid,
  toggleValueInArray,
  toggleValueInArrayWithLimit,
} from '../../../core/helpers/helpers';
import { UploadEditEvent } from '../../../core/models/app.models';
import { EventService } from '../../../core/services/event.service';
import { FormService } from '../../../core/services/form.service';
import { PaymentService } from '../../../core/services/payment.service';
import { UploadEventFormService } from '../../../core/services/upload-event-form.service';
import { GlobalState } from '../../../core/store';
import { appSelectors } from '../../../core/store/app';
import { ToastService } from '../../../shared/toast/toast.service';
import { MyEventsEffectsActions } from '../../my-events/store';
import { uploadEventCustomSelectors } from '../store/upload-event.selectors';
import {
  UploadCancelledGuardActions,
  UploadEventContainerActions,
  UploadEventEffectsActions,
  UploadSuccessfulGuardActions,
} from './index';

@Injectable({
  providedIn: 'root',
})
export class UploadEventEffects {
  uploadEventContainerCalled$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UploadEventContainerActions.uploadEventContainerCalled),
        withLatestFrom(this.store.select(appSelectors.selectInternalLabels)),
        map(([_, labels]) => {
          this.eventFormService
            .getUploadEventInformationFormGroup()
            .patchValue({
              internalLabel: labels && labels.length === 1 ? labels[0].id : -1,
            });
        }),
      ),
    { dispatch: false },
  );
  nextButtonStepOneClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(UploadEventContainerActions.nextButtonStepOneClicked),
      map(() => {
        this.eventFormService
          .getUploadEventInformationFormGroup()
          .markAllAsTouched();
      }),
      map(() => {
        if (this.eventFormService.getUploadEventInformationFormGroup().valid) {
          return UploadEventEffectsActions.stepOneFormValid();
        }
        return UploadEventEffectsActions.stepOneFormInvalid();
      }),
    ),
  );
  nextButtonStepTwoClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(UploadEventContainerActions.nextButtonStepTwoClicked),
      tap(() => {
        this.eventFormService
          .getUploadEventGenreTagsFormGroup()
          .markAllAsTouched();
      }),
      map(() => {
        if (this.eventFormService.getUploadEventGenreTagsFormGroup().valid) {
          return UploadEventEffectsActions.stepTwoFormValid();
        }
        return UploadEventEffectsActions.stepTwoFormInvalid();
      }),
    ),
  );
  nextButtonStepThreeClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(UploadEventContainerActions.nextButtonStepThreeClicked),
      tap(() => {
        this.eventFormService
          .getUploadEventTicketingFormGroup()
          .markAllAsTouched();
      }),
      map(() => {
        if (
          this.eventFormService.getUploadEventTicketingFormGroup().valid ||
          this.eventFormService.getUploadEventTicketingFormGroup().disabled
        ) {
          return UploadEventEffectsActions.stepThreeFormValid();
        }
        return UploadEventEffectsActions.stepThreeFormInvalid();
      }),
    ),
  );
  nextButtonStepFourClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(UploadEventContainerActions.nextButtonStepFourClicked),
      tap(() => {
        this.eventFormService
          .getUploadEventBoostersFormGroup()
          .markAllAsTouched();
      }),
      map(() => {
        if (this.eventFormService.getUploadEventBoostersFormGroup().valid) {
          return UploadEventEffectsActions.stepFourFormValid();
        }
        return UploadEventEffectsActions.stepFourFormInvalid();
      }),
    ),
  );
  uploadEventSuccessfulWithPayment$ = createEffect(() =>
    this.actions.pipe(
      ofType(UploadEventEffectsActions.uploadEventSuccessfulWithPayment),
      withLatestFrom(
        this.store.select(appSelectors.selectCustomer),
        this.store.select(uploadEventCustomSelectors.selectSelectedProducts),
        this.store.select(appSelectors.selectLanguage),
      ),
      switchMap(([_, customer, selectedProducts, language]) =>
        this.paymentService.createPayment(
          customer!.stripeCustomerId!,
          selectedProducts,
          language,
        ),
      ),
      map((paymentId) =>
        UploadEventEffectsActions.paymentStartedSuccessful({ paymentId }),
      ),
      catchError((_error) => [
        UploadEventEffectsActions.paymentStartedUnsuccessful(),
      ]),
    ),
  );
  dispatchPaymentProcess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UploadEventEffectsActions.paymentStartedSuccessful),
        map(({ paymentId }) => {
          this.paymentService.startPaymentProcess(paymentId);
        }),
      ),
    { dispatch: false },
  );
  uploadEvent$ = createEffect(() =>
    this.actions.pipe(
      ofType(UploadEventEffectsActions.uploadEventStarted),
      withLatestFrom(this.store),
      switchMap(([_, state]) => this.handleUploadEvent(state)),
    ),
  );
  uploadEventFromStorage$ = createEffect(() =>
    this.actions.pipe(
      ofType(UploadSuccessfulGuardActions.uploadEventPaymentSuccessful),
      switchMap(() => {
        return this.eventService
          .uploadEvent(JSON.parse(localStorage.getItem('newEvent')!))
          .pipe(
            map(() =>
              UploadEventEffectsActions.uploadEventFromStorageSuccessful(),
            ),
            catchError((_error) => [
              UploadEventEffectsActions.uploadEventFromStorageUnsuccessful(),
            ]),
          );
      }),
    ),
  );
  uploadClickedNew$ = createEffect(() =>
    this.actions.pipe(
      ofType(UploadEventContainerActions.uploadClicked),
      map(() => {
        return this.eventFormService.validateUploadForm();
      }),
      map((invalidSteps) => {
        if (invalidSteps.length > 0) {
          return UploadEventEffectsActions.uploadEventFormInvalid({
            invalidSteps,
          });
        } else {
          return UploadEventEffectsActions.formStepsValid();
        }
      }),
    ),
  );
  uploadEventUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          UploadEventEffectsActions.uploadEventUnsuccessful,
          UploadEventEffectsActions.paymentStartedUnsuccessful,
        ),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.uploadEvent.error.title',
            'izzo.admin.uploadEvent.error.text',
          ),
        ),
      ),
    { dispatch: false },
  );
  uploadEventFormInvalid$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UploadEventEffectsActions.uploadEventFormInvalid),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.uploadEvent.form.error.title',
            'izzo.admin.uploadEvent.form.error.text',
          ),
        ),
      ),
    { dispatch: false },
  );
  deleteNewEventFromStorage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          UploadEventEffectsActions.uploadEventFromStorageSuccessful,
          UploadEventEffectsActions.uploadEventFromStorageUnsuccessful,
        ),
        tap(() => localStorage.removeItem('newEvent')),
      ),
    { dispatch: false },
  );
  uploadEventPostponed$ = createEffect(() =>
    this.actions.pipe(
      ofType(UploadEventEffectsActions.uploadEventPostponed),
      withLatestFrom(this.store),
      tap(([_, state]) =>
        localStorage.setItem(
          'newEvent',
          JSON.stringify({
            name: state.uploadEvent.eventInformation.name!,
            startDate: makeDateValid(
              state.uploadEvent.eventInformation.startDate!,
            ),
            endDate: makeDateValid(state.uploadEvent.eventInformation.endDate!),
            startTime: state.uploadEvent.eventInformation.startTime!,
            endTime: state.uploadEvent.eventInformation.endTime!,
            genres: state.uploadEvent.selectedGenres,
            tags: state.uploadEvent.selectedTags,
            internalLabel: state.uploadEvent.eventInformation.internalLabel,
            location: { id: state.uploadEvent.eventInformation.location! },
            locationName: state.uploadEvent.eventInformation.locationName,
            locationStreet: state.uploadEvent.eventInformation.locationStreet,
            locationPlz: state.uploadEvent.eventInformation.locationPlz,
            locationProvince:
              state.uploadEvent.eventInformation.locationProvince,
            ageRestrictionMen:
              state.uploadEvent.eventInformation.ageRestrictionMen,
            ageRestrictionWomen:
              state.uploadEvent.eventInformation.ageRestrictionWomen,
            fromPrice:
              state.uploadEvent.eventInformation.fromPrice === '0.00'
                ? ''
                : state.uploadEvent.eventInformation.fromPrice ?? '',
            ticketLink: state.uploadEvent.eventInformation.ticketLink ?? '',
            fyraTicketLink:
              state.uploadEvent.eventInformation.fyraTicketLink ?? '',
            pressText: state.uploadEvent.eventInformation.pressText!,
            pinActive: state.uploadEvent.products['pin'].selected,
            flyerActive: state.uploadEvent.products['flyer'].selected,
            highlightActive: state.uploadEvent.products['mark'].selected,
            flyer: state.uploadEvent.eventInformation.flyer,
          }),
        ),
      ),
      map(() => UploadEventEffectsActions.uploadEventSuccessfulWithPayment()),
    ),
  );
  uploadClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(UploadEventEffectsActions.formStepsValid),
      withLatestFrom(
        this.store.select(
          uploadEventCustomSelectors.selectIsAnyProductSelected,
        ),
      ),
      map(([_, anyProductSelected]) => {
        if (anyProductSelected) {
          return UploadEventEffectsActions.uploadEventPostponed();
        }
        return UploadEventEffectsActions.uploadEventStarted();
      }),
    ),
  );
  uploadSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UploadEventEffectsActions.uploadEventSuccessful),
        withLatestFrom(this.store),
        tap(() => {
          this.eventFormService
            .getUploadEventTicketingFormGroup()
            .controls.fromPrice.enable();
          this.eventFormService
            .getUploadEventTicketingFormGroup()
            .controls.ticketLink.enable();
          this.eventFormService
            .getUploadEventTicketingFormGroup()
            .controls.fyraTicketLink.enable();

          this.eventFormService.getUploadEventInformationFormGroup().reset({
            name: '',
            startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
            endDate: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
            startTime: '',
            endTime: '',
            location: 0,
            internalLabel: -1,
            locationName: '',
            locationStreet: '',
            locationPlz: '',
            locationProvince: '',
            ageRestrictionWomen: '',
            ageRestrictionMen: '',
            pressText: '',
          });

          this.eventFormService.getUploadEventGenreTagsFormGroup().reset({
            genres: [],
            tags: [],
          });

          this.eventFormService.getUploadEventTicketingFormGroup().reset({
            fromPrice: '',
            ticketLink: '',
            fyraTicketLink: '',
          });

          this.eventFormService.getUploadEventBoostersFormGroup().reset({
            flyerUploaded: false,
            boosters: [],
          });
        }),
        map(([_, store]) =>
          this.router.navigate([store.app.language, 'my-events']),
        ),
      ),
    { dispatch: false },
  );
  toggledGenre$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UploadEventContainerActions.toggleGenreClicked),
        map(({ genreId }) =>
          this.eventFormService
            .getUploadEventGenreTagsFormGroup()
            .controls.genres.setValue(
              toggleValueInArrayWithLimit(
                this.eventFormService.getUploadEventGenreTagsFormGroup()
                  .controls.genres.value!,
                genreId,
                3,
              ),
            ),
        ),
      ),
    { dispatch: false },
  );
  toggledTag$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UploadEventContainerActions.toggleTagClicked),
        map(({ tagId }) =>
          this.eventFormService
            .getUploadEventGenreTagsFormGroup()
            .controls.tags.setValue(
              toggleValueInArrayWithLimit(
                this.eventFormService.getUploadEventGenreTagsFormGroup()
                  .controls.tags.value!,
                tagId,
                3,
              ),
            ),
        ),
      ),
    { dispatch: false },
  );
  selectedProduct$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UploadEventContainerActions.productSelectionToggled),
        map(({ product }) => {
          this.eventFormService
            .getUploadEventBoostersFormGroup()
            .controls.boosters.setValue(
              toggleValueInArray(
                this.eventFormService.getUploadEventBoostersFormGroup().controls
                  .boosters.value!,
                product.key,
              ),
            );
        }),
      ),
    { dispatch: false },
  );
  flyerCropped$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UploadEventContainerActions.flyerCropped),
        map(({ flyer }) => {
          this.eventFormService
            .getUploadEventBoostersFormGroup()
            .controls.flyerUploaded.setValue(!!flyer);
        }),
      ),
    { dispatch: false },
  );

  uploadEventSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UploadEventEffectsActions.uploadEventSuccessful),
        tap(() =>
          this.toastService.showSuccess(
            'izzo.admin.uploadEvent.success.title',
            'izzo.admin.uploadEvent.success.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  uploadEventFromStorageUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UploadEventEffectsActions.uploadEventFromStorageUnsuccessful),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.uploadStorage.error.title',
            'izzo.admin.uploadStorage.error.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  uploadEventPaymentUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UploadCancelledGuardActions.uploadEventPaymentUnsuccessful),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.uploadStorageCancelled.error.title',
            'izzo.admin.uploadStorageCancelled.error.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  uploadEventFromStorageSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(UploadEventEffectsActions.uploadEventFromStorageSuccessful),
        tap(() =>
          this.toastService.showSuccess(
            'izzo.admin.uploadEvent.success.title',
            'izzo.admin.uploadEvent.success.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  deleteEventSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEventsEffectsActions.deleteEventSuccessful),
        tap(() =>
          this.toastService.showSuccess(
            'izzo.admin.myEvents.delete.success.title',
            'izzo.admin.myEvents.delete.success.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  deleteEventFailed$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyEventsEffectsActions.deleteEventFailed),
        tap(() =>
          this.toastService.showError(
            'izzo.admin.myEvents.delete.failed.title',
            'izzo.admin.myEvents.delete.failed.text',
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<GlobalState>,
    private readonly formService: FormService,
    private readonly eventFormService: UploadEventFormService,
    private readonly router: Router,
    private readonly eventService: EventService,
    private readonly paymentService: PaymentService,
    private readonly toastService: ToastService,
  ) {}

  private handleUploadEvent(state: GlobalState): Observable<Action> {
    const eventInfo = state.uploadEvent.eventInformation;
    const location = eventInfo.location ?? '';

    const eventPayload: UploadEditEvent = {
      name: eventInfo.name!,
      startDate: makeDateValid(eventInfo.startDate!),
      endDate: makeDateValid(eventInfo.endDate!),
      startTime: eventInfo.startTime!,
      endTime: eventInfo.endTime!,
      location: { id: location },
      locationName: eventInfo.locationName ?? '',
      locationStreet: eventInfo.locationStreet ?? '',
      locationPlz: eventInfo.locationPlz ?? '',
      locationProvince: eventInfo.locationProvince ?? '',
      internalLabel: eventInfo.internalLabel!,
      genres: state.uploadEvent.selectedGenres,
      tags: state.uploadEvent.selectedTags,
      ageRestrictionMen: eventInfo.ageRestrictionMen!,
      ageRestrictionWomen: eventInfo.ageRestrictionWomen!,
      fromPrice:
        eventInfo.fromPrice === '0.00' ? '' : eventInfo.fromPrice ?? '',
      ticketLink: eventInfo.ticketLink ?? '',
      fyraTicketLink: eventInfo.fyraTicketLink ?? '',
      pressText: eventInfo.pressText!,
      pinActive: false,
      highlightActive: false,
      flyerActive: !!eventInfo.flyer,
      flyer: eventInfo.flyer || null,
    };

    return this.eventService.uploadEvent(eventPayload).pipe(
      map(() => UploadEventEffectsActions.uploadEventSuccessful()),
      catchError(() => [UploadEventEffectsActions.uploadEventUnsuccessful()]),
    );
  }
}
