import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { first, Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JWT_KEY } from '../constants';
import { selectLanguage } from '../store/app/app.selectors';
import { AppState } from '../store/app/app.state';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private readonly cookieService = inject(CookieService);
  private readonly store = inject(Store<AppState>);

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.store.select(selectLanguage).pipe(
      first(),
      switchMap((language) => {
        const isApiUrl = req.url.startsWith(environment.api);
        const hasToken = this.cookieService.check(JWT_KEY);

        if (!isApiUrl || !hasToken) {
          const nonJwtReq = req.clone({
            setHeaders: { 'x-custom-lang': language },
          });
          return next.handle(nonJwtReq);
        }

        const token = this.cookieService.get(JWT_KEY);

        const jwtReq = req.clone({
          setHeaders: {
            'Authorization': `Bearer ${token}`,
            'x-custom-lang': language,
          },
        });

        return next.handle(jwtReq);
      }),
    );
  }
}
