import { createFeature, createReducer, on } from '@ngrx/store';
import { ClubListActions } from './actions';
import { ClubListState } from './club-list.state';

export const initialState: Readonly<ClubListState> = {
  clubs: [],
};

export const clubListReducers = createReducer(
  initialState,
  on(ClubListActions.loadClubsSuccess, (state, { data }) => ({
    ...state,
    clubs: data,
  })),
);

export const clubListFeature = createFeature({
  name: 'clubList',
  reducer: clubListReducers,
});
