import { createFeature, createReducer, on } from '@ngrx/store';
import { ClientCreateFormActions } from './actions';
import { ClientCreateFormState } from './client-create-form.state';

export const initialState: Readonly<ClientCreateFormState> = {
  steps: [
    'izzo.admin.crm.person',
    'izzo.admin.crm.address',
    'izzo.admin.crm.summary',
  ],
  currentStep: 1,
};

function MinMax(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

export const clientCreateFormReducers = createReducer(
  initialState,
  on(ClientCreateFormActions.changeStep, (state, { step }) => ({
    ...state,
    currentStep: MinMax(step, 1, state.steps.length),
  })),
  on(ClientCreateFormActions.resetForm, (state) => ({
    ...state,
    currentStep: 1,
  })),
);

export const clientCreateFormFeature = createFeature({
  name: 'crmClientCreate',
  reducer: clientCreateFormReducers,
});
