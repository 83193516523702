import { Store } from '@ngrx/store';
import { GlobalState } from '../../../core/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FormService } from '../../../core/services/form.service';
import { Router } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import {
  EMPTY,
  catchError,
  exhaustMap,
  filter,
  forkJoin,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ListReadOnlyContainerActions, listReadOnlySelectors } from './index';
import { ListService } from 'src/app/core/services/list.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { FileDownloadService } from 'src/app/core/services/file-download.service';

@Injectable({
  providedIn: 'root',
})
export class ListReadOnlyEffects {
  #toastService = inject(ToastService);
  #actions = inject(Actions);
  #translate = inject(TranslateService);

  constructor(
    private readonly actions: Actions,
    private readonly listService: ListService,
    private readonly formService: FormService,
    private readonly store: Store<GlobalState>,
    private readonly fileDownloadService: FileDownloadService,
  ) {}

  importCsv$ = createEffect(() =>
    this.actions.pipe(
      ofType(ListReadOnlyContainerActions.importCsv),
      map((x) => ({
        file: x.file,
        acceptedTypes: ['application/vnd.ms-excel', 'text/csv'],
      })),
      filter(
        (x) =>
          !!x.file &&
          x.acceptedTypes.includes(x.file.type) &&
          x.file.name.endsWith('.csv'),
      ),
      tap(() =>
        this.#toastService.showInfo(
          'izzo.admin.shared.info.csvImportStarted.title',
          'izzo.admin.shared.info.csvImportStarted.message',
        ),
      ),
      withLatestFrom(this.store.select(listReadOnlySelectors.selectList)),
      switchMap(([x, list]) => {
        return this.listService.importCsv(x.file, list.id!);
      }),
      map((result) => {
        if (result.status !== 'completed') {
          return ListReadOnlyContainerActions.importCsvUnsuccessful({ result });
        } else {
          return ListReadOnlyContainerActions.importCsvSuccessful();
        }
      }),
    ),
  );

  importCsvSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ListReadOnlyContainerActions.importCsvSuccessful),
        withLatestFrom(this.store),
        tap(() => {
          this.#toastService.showSuccess(
            'izzo.admin.shared.success.csvImport.title',
            'izzo.admin.shared.success.csvImport.message',
            { dismiss: 5000 },
          );
        }),
      ),
    { dispatch: false },
  );

  importCsvUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ListReadOnlyContainerActions.importCsvUnsuccessful),
        tap(({ result }) => {
          this.#toastService.showError(result.details!);
        }),
      ),
    { dispatch: false },
  );

  exportCsv$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ListReadOnlyContainerActions.exportCsvLInviteesFromList),
        tap(() =>
          this.#toastService.showInfo(
            'izzo.admin.shared.info.csvExportStarted.title',
            'izzo.admin.shared.info.csvExportStarted.message',
          ),
        ),
        exhaustMap(({ listId }) =>
          forkJoin({
            fileName: this.#translate.get('izzo.admin.list.inviteesList').pipe(
              withLatestFrom(
                this.store.select(listReadOnlySelectors.selectList),
              ),
              map(([x, list]) => ({
                name: x + ' ' + list.name,
                today: new Date().toISOString().split('T')[0],
              })),
              map(({ name, today }) => `${name}_${today}.csv`),
            ),
            response: this.listService.exportCsvByListId(listId),
          }).pipe(
            catchError(() => {
              this.#toastService.showError(
                'izzo.admin.shared.errors.csvExport',
                'izzo.admin.shared.errors.unexpected',
              );
              return EMPTY;
            }),
            filter((x) => !!x.response.body),
            tap((x) =>
              this.fileDownloadService.downloadBlob(
                x.response.body!,
                x.fileName,
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  getCsvTemplateClick$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ListReadOnlyContainerActions.getCsvTemplateClick),
        tap(() =>
          this.#toastService.showInfo(
            'izzo.admin.shared.info.downloadStarted.title',
            'izzo.admin.shared.info.downloadStarted.message',
          ),
        ),
        exhaustMap(() =>
          forkJoin({
            fileName: this.#translate.get('izzo.admin.list.templateName'),
            response: this.listService.getCsvTemplate(),
          }).pipe(
            catchError(() => {
              this.#toastService.showError(
                'izzo.admin.shared.errors.csvDownload',
                'izzo.admin.shared.errors.unexpected',
              );
              return EMPTY;
            }),
            filter((x) => !!x.response.body),
            tap((x) =>
              this.fileDownloadService.downloadBlob(
                x.response.body!,
                x.fileName,
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  addInviteeClickedReadOnly$ = createEffect(() =>
    this.actions.pipe(
      ofType(ListReadOnlyContainerActions.addInviteeToListClickedReadOnly),
      switchMap(({ listInvitee }) =>
        this.listService.addInviteeToList({
          ...listInvitee,
          list: listInvitee.list!,
          timeStamp: null,
          isReadOnly: true,
        }),
      ),
      map(() =>
        ListReadOnlyContainerActions.addInviteeToListSuccessfulReadOnly(),
      ),
      catchError((_error) => [
        ListReadOnlyContainerActions.addInviteeToListUnsuccessfulReadOnly(),
      ]),
    ),
  );

  listOpened$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        ListReadOnlyContainerActions.initList,
        ListReadOnlyContainerActions.importCsvSuccessful,
        ListReadOnlyContainerActions.importCsvUnsuccessful,
      ),
      withLatestFrom(this.store.select(listReadOnlySelectors.selectList)),
      switchMap(([_, list]) => {
        return this.listService.getListInviteesByListId(list!.id!);
      }),
      map((listInvitees) =>
        ListReadOnlyContainerActions.listOpened({ listInvitees }),
      ),
    ),
  );

  addInviteeToListSuccessfulReadOnly$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ListReadOnlyContainerActions.addInviteeToListSuccessfulReadOnly),
        tap(() => {
          this.formService.getAddInviteeToListFormGroup().reset({
            name: '',
            firstName: '',
            comment: '',
          }),
            this.#toastService.showSuccess(
              'izzo.admin.list.addInvitee.success.title',
              'izzo.admin.list.addInvitee.success.text',
            );
        }),
      ),
    { dispatch: false },
  );

  addInviteeToListUnsuccessfulReadOnly$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          ListReadOnlyContainerActions.addInviteeToListUnsuccessfulReadOnly,
        ),
        tap(() => {
          this.formService.getAddInviteeToListFormGroup().reset({
            name: '',
            firstName: '',
            comment: '',
          }),
            this.#toastService.showError(
              'izzo.admin.list.addInvitee.error.title',
              'izzo.admin.list.addInvitee.error.text',
            );
        }),
      ),
    { dispatch: false },
  );

  maxInviteesReached$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ListReadOnlyContainerActions.maxInviteesReachedReadOnly),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.list.maxInviteesReached.title',
            'izzo.admin.list.maxInviteesReached.text',
          );
        }),
      ),
    { dispatch: false },
  );

  currentTimeOverValidTime$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ListReadOnlyContainerActions.currentTimeOverValidTime),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.list.currentTimeOverValidTime.title',
            'izzo.admin.list.currentTimeOverValidTime.text',
          );
        }),
      ),
    { dispatch: false },
  );
}
