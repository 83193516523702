import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  EMPTY,
  exhaustMap,
  filter,
  finalize,
  map,
  of,
  tap,
  withLatestFrom,
} from 'rxjs';
import { LoungeService } from 'src/app/core/services/lounge.service';
import { GlobalState } from 'src/app/core/store';
import { appSelectors } from 'src/app/core/store/app';
import { PackageApiService } from 'src/app/features/package/package-api.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ClubApiService } from '../../club-api.service';
import { ClubEditService } from '../club-edit.service';
import { ClubEditFormActions } from './actions';
import { mapClubFormToClubDetails } from '../../../../core/helpers/mappers';

@Injectable()
export class ClubEditFormEffects {
  #actions = inject(Actions);
  #clubApi = inject(ClubApiService);
  public readonly loadClub$ = createEffect(() =>
    this.#actions.pipe(
      ofType(ClubEditFormActions.loadClub),
      exhaustMap((x) => this.#clubApi.getClubDetails(x.id, x.status)),
      map((club) => ClubEditFormActions.loadClubSuccess({ data: club! })),
    ),
  );
  #packageApi = inject(PackageApiService);
  public readonly deletePackage$ = createEffect(() =>
    this.#actions.pipe(
      ofType(ClubEditFormActions.deletePackage),
      exhaustMap(({ packageId }) =>
        this.#packageApi
          .deletePackage(packageId)
          .pipe(
            map(() => ClubEditFormActions.deletePackageSuccess({ packageId })),
          ),
      ),
    ),
  );
  #toastService = inject(ToastService);
  #globalStore = inject(Store<GlobalState>);
  #router = inject(Router);
  public readonly addPackage$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClubEditFormActions.addPackage),
        withLatestFrom(this.#globalStore.select(appSelectors.selectLanguage)),
        tap(([{ locationId, clubId }, lang]) =>
          this.#router.navigate([lang, 'package', locationId, -1], {
            state: { callback: [lang, 'club', clubId] },
          }),
        ),
      ),
    { dispatch: false },
  );
  public readonly editPackage$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClubEditFormActions.editPackage),
        withLatestFrom(this.#globalStore.select(appSelectors.selectLanguage)),
        tap(([{ clubId, locationId, packageId }, lang]) =>
          this.#router.navigate([lang, 'package', locationId, packageId], {
            state: { callback: [lang, 'club', clubId] },
          }),
        ),
      ),
    { dispatch: false },
  );
  public readonly addLounge$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClubEditFormActions.addLounge),
        withLatestFrom(this.#globalStore.select(appSelectors.selectLanguage)),
        tap(([{ clubId, locationId }, lang]) =>
          this.#router.navigate(
            [lang, 'create-lounge', 'location', locationId],
            {
              state: { callback: [lang, 'club', clubId] },
            },
          ),
        ),
      ),
    { dispatch: false },
  );
  public readonly editLounge$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClubEditFormActions.editLounge),
        withLatestFrom(this.#globalStore.select(appSelectors.selectLanguage)),
        tap(([{ clubId, loungeId }, lang]) =>
          this.#router.navigate([lang, 'edit-lounge', loungeId], {
            state: { callback: [lang, 'club', clubId], hideReservation: true },
          }),
        ),
      ),
    { dispatch: false },
  );
  #editService = inject(ClubEditService);
  public readonly clubInformationNextClicked = createEffect(() =>
    this.#actions.pipe(
      ofType(ClubEditFormActions.clubInformationNextClicked),
      map(() => {
        const form = this.#editService.clubForm.controls.location;
        form.markAllAsTouched();

        if (form.valid) {
          return ClubEditFormActions.clubInformationValid();
        }

        return ClubEditFormActions.clubInformationInvalid();
      }),
    ),
  );
  public readonly contactDataNextClicked = createEffect(() =>
    this.#actions.pipe(
      ofType(ClubEditFormActions.contactDataNextClicked),
      map(() => {
        const form = this.#editService.clubForm.controls.contact;
        form.markAllAsTouched();

        if (form.valid) {
          return ClubEditFormActions.contactDataValid();
        }

        return ClubEditFormActions.contactDataInvalid();
      }),
    ),
  );
  public readonly socialsNextClicked = createEffect(() =>
    this.#actions.pipe(
      ofType(ClubEditFormActions.socialsNextClicked),
      map(() => {
        const form = this.#editService.clubForm.controls.socials;
        form.markAllAsTouched();

        if (form.valid) {
          return ClubEditFormActions.socialsValid();
        }

        return ClubEditFormActions.socialsInvalid();
      }),
    ),
  );
  public readonly setClubForm = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClubEditFormActions.loadClubSuccess),
        tap(
          ({ data }) =>
            data &&
            this.#editService.clubForm.patchValue({
              id: data.id,
              location: {
                ...data.location,
                id: data.location.id,
                place: data.location?.place || {
                  id: 0,
                  plz: '',
                  province: '',
                },
                description: data.description,
                region: data.location?.region || {
                  id: 0,
                  region: '',
                },
              },
              contact: {
                email: data.email,
                phoneNumber: data.phoneNumber,
              },
              socials: {
                instagramUrl: data.instagramUrl,
                facebookUrl: data.facebookUrl,
                twitterUrl: data.twitterUrl,
                websiteUrl: data.websiteUrl,
              },
              logo: {
                logoUrl: data.logoUrl ? data.logoUrl : '',
                logoOriginalUrl: data.logoUrl ? data.logoUrl : '',
              },
            }),
        ),
      ),
    { dispatch: false },
  );
  public readonly saveClub$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClubEditFormActions.saveClub),
        map(({ id, status }) => ({
          form: this.#editService.clubForm,
          id,
          status,
        })),
        filter(({ form }) => {
          form.markAllAsTouched();

          return form.valid;
        }),
        tap(({ form }) => form.disable()),
        withLatestFrom(this.#globalStore.select(appSelectors.selectLanguage)),
        exhaustMap(([{ form, id, status }, lang]) => {
          this.#editService.validateAllFormFields();
          return this.#clubApi
            .updateClub(id, status, {
              ...mapClubFormToClubDetails(form.getRawValue()),
            })
            .pipe(
              tap(() => form.markAsPristine()),
              finalize(() => form.enable()),
              tap(() => {
                this.showSaveSuccess();
                this.#router.navigate([lang, 'club']);
              }),
              catchError(() => {
                this.showSaveError();
                return EMPTY;
              }),
            );
        }),
      ),
    { dispatch: false },
  );
  public readonly createClub$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClubEditFormActions.createClub),
        map(() => this.#editService.clubForm),
        filter((form) => {
          const logoFile = form.controls.logo.controls.logoUrl.value;

          if (!logoFile) {
            this.#toastService.showError(
              'izzo.admin.club.logoMissing',
              'izzo.admin.club.logoMissingMessage',
            );
          }

          const invalid = form.invalid || !logoFile;

          if (invalid) {
            form.markAllAsTouched();
          }

          return !invalid;
        }),
        tap((form) => form.disable()),
        exhaustMap((form) => {
          this.#editService.validateAllFormFields();
          return this.#clubApi
            .createClub({ ...mapClubFormToClubDetails(form.getRawValue()) })
            .pipe(
              tap(() => form.markAsPristine()),
              finalize(() => form.enable()),
              tap(() => this.showSaveSuccess()),
              withLatestFrom(
                this.#globalStore.select(appSelectors.selectLanguage),
              ),
              tap(([id, lang]) => this.#router.navigate([lang, 'club'])),
              catchError((err) => {
                this.showSaveError();
                console.error(err);
                return EMPTY;
              }),
            );
        }),
      ),
    { dispatch: false },
  );
  #loungeService = inject(LoungeService);
  public readonly deleteLounge$ = createEffect(() =>
    this.#actions.pipe(
      ofType(ClubEditFormActions.deleteLounge),
      exhaustMap(({ loungeId }) =>
        this.#loungeService.deleteLounge(loungeId).pipe(
          map(() =>
            ClubEditFormActions.deleteLoungeSuccess({ loungeId: loungeId }),
          ),
          catchError((err) => {
            console.error(err);
            return of(
              ClubEditFormActions.deleteLoungeError({ loungeId: loungeId }),
            );
          }),
        ),
      ),
    ),
  );
  #location = inject(Location);
  goBack$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClubEditFormActions.backClicked),
        map(() => {
          this.#editService.clubForm.reset();
          this.#location.back();
        }),
      ),
    { dispatch: false },
  );

  private showSaveSuccess(): void {
    this.#toastService.showSuccess(
      'izzo.admin.shared.success.changesSaved.title',
      'izzo.admin.club.success.changesSaved.message',
      { dismiss: 5000 },
    );
  }

  private showSaveError(): void {
    this.#toastService.showError(
      'izzo.admin.shared.errors.save.title',
      'izzo.admin.shared.errors.save.message',
    );
  }
}
