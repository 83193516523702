import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { SubscriptionData } from '../models/app.models';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  // @ts-ignore
  stripe = Stripe(environment.stripeKey);

  constructor(private http: HttpClient) {}

  activateOrbit(customerId: string, lang: string) {
    return this.http
      .post(environment.api + '/subscription/create-checkout-session', {
        customerId,
        lang,
      })
      .pipe(
        map((response: any) => response.id),
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }

  startPaymentProcess(sessionId: string) {
    return this.stripe.redirectToCheckout({ sessionId });
  }

  getSubscriptionStatus(customerId: string) {
    return this.http.get<SubscriptionData>(
      environment.api + `/subscription/status/${customerId}`,
    );
  }

  cancelSubscription(subscriptionId: string) {
    return this.http
      .delete(environment.api + `/subscription/${subscriptionId}/cancel`)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }

  undoCancelSubscription(subscriptionId: string) {
    return this.http
      .post(environment.api + `/subscription/${subscriptionId}/undo-cancel`, {})
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }
}
