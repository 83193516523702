import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector } from '@angular/core';
import { SharedModule } from '../shared.module';
import { ToastContainerComponent } from './toast-container/toast-container.component';
import { Toast, ToastOptions } from './toast.type';

@Injectable({ providedIn: 'root', deps: [Overlay, SharedModule] })
export class ToastService {
  #overlayRef: OverlayRef;
  #containerRef: ComponentRef<ToastContainerComponent>;

  constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector,
  ) {
    this.#overlayRef = this.overlay.create();
    this.#containerRef = this.#overlayRef.attach(
      new ComponentPortal(ToastContainerComponent, null, this.injector),
    );
  }

  public showInfo(title: string, text?: string, options?: ToastOptions): void {
    this.show({ title, text, type: 'info' });
  }

  public showSuccess(
    title: string,
    text?: string,
    options?: ToastOptions,
  ): void {
    this.show({ title, text, type: 'success', options });
  }

  public showWarning(
    title: string,
    text?: string,
    options?: ToastOptions,
  ): void {
    this.show({ title, text, type: 'warning', options });
  }

  public showError(title: string, text?: string, options?: ToastOptions): void {
    this.show({ title, text, type: 'error', options });
  }

  private show(toast: Omit<Toast, 'id'>): void {
    this.#containerRef.instance.show({ ...toast, id: Symbol() });
  }
}
