import { Store } from '@ngrx/store';
import { GlobalState } from '../../../core/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FormService } from '../../../core/services/form.service';
import { Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs';
import {
  CreateListContainerActions,
  CreateListEffectsActions,
  createListSelectors,
} from './index';
import { OrbitState } from '../../orbit/store/orbit.state';
import { ListService } from 'src/app/core/services/list.service';
import { appSelectors } from 'src/app/core/store/app';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CreateListEffects {
  listTypeClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(CreateListContainerActions.listTypeClicked),
      tap(() => {
        this.formService.getCreateListStepOneFormGroup().markAllAsTouched();
      }),
      map(() => {
        return CreateListEffectsActions.stepOneFormValid();
      }),
    ),
  );
  createList$ = createEffect(() =>
    this.actions.pipe(
      ofType(CreateListContainerActions.createListClicked),
      withLatestFrom(
        this.orbitStore.select(createListSelectors.selectEventInformation),
      ),
      tap(() => {
        this.formService.getCreateListStepTwoFormGroup().markAllAsTouched();
      }),
      switchMap(([_, eventInformation]) =>
        this.listService
          .createList({
            ...this.formService.getCreateListStepTwoFormGroup().getRawValue(),
            listType: {
              id: this.formService.getCreateListStepOneFormGroup().controls
                .listType.value!,
            },
            event: eventInformation,
            link: '',
            validTillDate:
              this.formService.getCreateListStepTwoFormGroup().controls
                .validTillDate.value!,
            validTillTime:
              this.formService.getCreateListStepTwoFormGroup().controls
                .validTillTime.value!,
            listInvitee: [],
          })
          .pipe(
            map((listId) =>
              CreateListEffectsActions.createListSuccessful({ listId }),
            ),
            catchError((_error) => [
              CreateListEffectsActions.createListUnsuccessful(),
            ]),
          ),
      ),
    ),
  );
  backToOpenOrbitPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(CreateListContainerActions.backClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          this.formService.getCreateListStepOneFormGroup().reset({
            listType: 0,
          });

          this.formService.getCreateListStepTwoFormGroup().reset({
            name: '',
            maxInvitees: 0,
            namePromoter: '',
            emailPromoter: '',
            price: '',
            validTillDate: '',
            validTillTime: '',
          });

          this.router.navigate([language, 'orbit', 'event', event.id]);
        }),
      ),
    { dispatch: false },
  );
  #toastService = inject(ToastService);
  uploadSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(CreateListEffectsActions.createListSuccessful),
        tap(() => {
          this.#toastService.showSuccess(
            'izzo.admin.list.create.success.title',
            'izzo.admin.list.create.success.text',
          );

          this.formService.getCreateListStepOneFormGroup().reset({
            listType: 0,
          });

          this.formService.getCreateListStepTwoFormGroup().reset({
            name: '',
            maxInvitees: 0,
            namePromoter: '',
            emailPromoter: '',
            price: '',
            validTillDate: '',
            validTillTime: '',
          });
        }),
        withLatestFrom(
          this.store,
          this.store.select(createListSelectors.selectEventInformation),
        ),
        map(([_, store, event]) =>
          this.router.navigate([
            store.app.language,
            'orbit',
            'event',
            event.id,
          ]),
        ),
      ),
    { dispatch: false },
  );
  uploadFailed$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(CreateListEffectsActions.createListUnsuccessful),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.list.create.error.title',
            'izzo.admin.list.create.error.text',
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<GlobalState>,
    private readonly orbitStore: Store<OrbitState>,
    private readonly formService: FormService,
    private readonly listService: ListService,
    private readonly router: Router,
    private readonly location: Location,
  ) {}
}
