import {
  animate,
  sequence,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeAnimation = trigger('fade', [
  transition(':leave', [
    style({
      opacity: 1,
    }),
    sequence([
      animate(
        '.25s ease',
        style({
          height: '*',
          opacity: 0.2,
          transform: 'translateY(-50px) scale(0.5)',
        }),
      ),
      animate(
        '.05s ease',
        style({
          height: 0,
          opacity: 0,
          transform: 'translateY(-100px) scale(0)',
        }),
      ),
    ]),
  ]),
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(-100px) scale(0)',
      height: 0,
    }),
    sequence([
      animate(
        '.1s ease',
        style({
          height: '*',
          opacity: 0.5,
          transform: 'translateY(0px) scale(0.5)',
        }),
      ),
      animate(
        '.1s ease',
        style({
          height: '*',
          opacity: 1,
          transform: 'translateY(0px) scale(1)',
        }),
      ),
    ]),
  ]),
]);
