import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Label } from '../models/app.models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InternalLabelService {
  constructor(private http: HttpClient) {}

  getInternalLabelsByCustomerId(customerId: number): Observable<Label[]> {
    return this.http.get<Label[]>(
      environment.api + `/internalLabel/${customerId}`,
    );
  }

  editLabel(internalLabel: Label) {
    return this.http.put(environment.api + '/internalLabel', internalLabel);
  }

  createLabel(internalLabel: Label) {
    return this.http.post(environment.api + '/internalLabel', internalLabel);
  }

  deleteLabel(labelId: number) {
    return this.http.delete(environment.api + `/internalLabel/${labelId}`);
  }
}
