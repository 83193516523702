@if (isLoggedIn | async) {
  <izzo-header (languageClicked)="onLanguageClicked($event)"
    (logoutClicked)="onLogoutClicked()"
    (uploadEventClicked)="onUploadEventClicked()"
    [clubLabel]="'izzo.admin.header.club' | translate"
    [contactLabel]="'izzo.admin.header.contact' | translate"
    [currentLanguage]="currentLanguage$ | async"
    [dashboardLabel]="'izzo.admin.header.dashboard' | translate"
    [languages]="languages"
    [logoutLabel]="'izzo.admin.header.logout' | translate"
    [myEventsLabel]="'izzo.admin.header.myEvents' | translate"
    [profileLabel]="'izzo.admin.header.profile' | translate"
    [roles]="roles$ | async"
    [subscription]="subscription$ | async"
    [uploadEventsLabel]="'izzo.admin.header.uploadEvents' | translate"
    >
  </izzo-header>
}
<router-outlet></router-outlet>
@if (isLoggedIn | async) {
  <izzo-footer (languageClicked)="onLanguageClicked($event)"
    [aboutLink]="'izzo.admin.footer.linkAbout' | translate"
    [agbLink]="'izzo.admin.footer.linkAGB' | translate"
    [contactLink]="'izzo.admin.footer.linkContact' | translate"
    [contactsButtonLabel]="'izzo.admin.footer.contactsButton' | translate"
    [contactsTextLabel]="'izzo.admin.footer.contactsText' | translate"
    [contactsTitleLabel]="'izzo.admin.footer.contactsTitle' | translate"
    [currentLanguage]="currentLanguage$ | async"
    [facebookLink]="'izzo.admin.footer.linkFacebook' | translate"
    [faqLink]="'izzo.admin.footer.linkFAQ' | translate"
    [impressumLink]="'izzo.admin.footer.linkImpressum' | translate"
    [instagramLink]="'izzo.admin.footer.linkInstagram' | translate"
    [languages]="languages"
    [linkedInLink]="'izzo.admin.footer.linkLinkedIn' | translate"
    [mediakitLink]="'izzo.admin.footer.linkMediakit' | translate"
    [privacyLink]="'izzo.admin.footer.linkPrivacy' | translate"
    [saferPartyButtonLabel]="'izzo.admin.footer.saferPartyButton' | translate"
    [saferPartyLink]="'izzo.admin.footer.linkSaferParty' | translate"
    [saferPartyTextLabel]="'izzo.admin.footer.saferPartyText' | translate"
    [saferPartyTitleLabel]="'izzo.admin.footer.saferPartyTitle' | translate"
    [servicesLink]="'izzo.admin.footer.linkServices' | translate"
  ></izzo-footer>
}

<div class="toast-container" toastHost>
  <ng-template></ng-template>
</div>
