import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FormService } from '../../../core/services/form.service';
import { ListService } from '../../../core/services/list.service';
import { GlobalState } from '../../../core/store';
import { appSelectors } from '../../../core/store/app';
import { ToastService } from './../../../shared/toast/toast.service';

import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { OrbitContainerActions, orbitSelectors } from '../../orbit/store/index';
import { OrbitState } from '../../orbit/store/orbit.state';
import {
  EditListContainerActions,
  EditListEffectsActions,
  editListSelectors,
} from './index';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class EditListEffects {
  patchEditValues$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.editListClicked),
        withLatestFrom(this.store.select(orbitSelectors.selectEditList)),
        tap(([_, list]) => {
          this.formService.patchEditListFormGroup(list);
        }),
      ),
    { dispatch: false },
  );
  listTypeClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditListContainerActions.listTypeClicked),
      tap(() => {
        this.formService.getCreateListStepOneFormGroup().markAllAsTouched();
      }),
      map(() => {
        return EditListEffectsActions.stepOneFormValid();
      }),
    ),
  );
  editList$ = createEffect(() =>
    this.actions.pipe(
      ofType(EditListContainerActions.editListClicked),
      withLatestFrom(
        this.orbitStore.select(orbitSelectors.selectEditList),
        this.orbitStore.select(editListSelectors.selectEventInformation),
      ),
      filter(([_, list]) => {
        const isUnderMaxInvitees =
          this.formService.getEditListStepTwoFormGroup().controls.maxInvitees
            .value! >= list.listInvitee.length;
        if (!isUnderMaxInvitees) {
          this.store.dispatch(
            EditListEffectsActions.listInviteesIsOverMaxInvitees(),
          );
        }
        return isUnderMaxInvitees;
      }),
      switchMap(([_, list, eventInformation]) => {
        return this.listService
          .updateList({
            id: list.id!,
            listType: {
              id: this.formService.getEditListStepOneFormGroup().controls
                .listType.value!,
            },
            name: this.formService.getEditListStepTwoFormGroup().controls.name
              .value!,
            maxInvitees:
              this.formService.getEditListStepTwoFormGroup().controls
                .maxInvitees.value!,
            namePromoter:
              this.formService.getEditListStepTwoFormGroup().controls
                .namePromoter.value!,
            emailPromoter:
              this.formService.getEditListStepTwoFormGroup().controls
                .emailPromoter.value!,
            price:
              this.formService.getEditListStepTwoFormGroup().controls.price
                .value!,
            event: eventInformation,
            link: '',
            validTillDate:
              this.formService.getEditListStepTwoFormGroup().controls
                .validTillDate.value!,
            validTillTime:
              this.formService.getEditListStepTwoFormGroup().controls
                .validTillTime.value!,
            listInvitee: list.listInvitee!,
          })
          .pipe(
            map(() => EditListEffectsActions.editListSuccessful()),
            catchError((_error) => [
              EditListEffectsActions.editListUnsuccessful(),
            ]),
          );
      }),
    ),
  );
  backToOpenOrbitPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditListContainerActions.backClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          this.router.navigate([language, 'orbit', 'event', event.id]);
        }),
      ),
    { dispatch: false },
  );
  #toastService = inject(ToastService);
  editSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditListEffectsActions.editListSuccessful),
        withLatestFrom(this.store.select(editListSelectors.selectList)),
        tap(() => {
          this.#toastService.showSuccess(
            'izzo.admin.list.edit.success.title',
            'izzo.admin.list.edit.success.text',
          );

          this.formService.getEditListStepOneFormGroup().reset({
            listType: 0,
          });

          this.formService.getEditListStepTwoFormGroup().reset({
            name: '',
            maxInvitees: 0,
            namePromoter: '',
            emailPromoter: '',
            price: '',
            validTillDate: '',
            validTillTime: '',
          });
        }),
        withLatestFrom(
          this.store,
          this.store.select(editListSelectors.selectEventInformation),
        ),
        map(([_, store, event]) =>
          this.router.navigate([
            store.app.language,
            'orbit',
            'event',
            event.id,
          ]),
        ),
      ),
    { dispatch: false },
  );
  editFailed$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditListEffectsActions.editListUnsuccessful),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.list.edit.error.title',
            'izzo.admin.list.edit.error.text',
          );
        }),
      ),
    { dispatch: false },
  );
  listInviteesIsOverMaxInvitees$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EditListEffectsActions.listInviteesIsOverMaxInvitees),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.list.edit.error.title',
            'izzo.admin.list.edit.maxInviteesReached.error.text',
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<GlobalState>,
    private readonly orbitStore: Store<OrbitState>,
    private readonly formService: FormService,
    private readonly listService: ListService,
    private readonly router: Router,
  ) {}
}
