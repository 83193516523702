import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  EMPTY,
  exhaustMap,
  filter,
  finalize,
  map,
  tap,
  withLatestFrom,
} from 'rxjs';
import { GlobalState } from 'src/app/core/store';
import { appSelectors } from 'src/app/core/store/app';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { PackageApiService } from '../../package-api.service';
import { PackageEditFormService } from '../package-edit-form.service';
import { PackageEditFormActions } from './actions';

@Injectable()
export class PackageEditFormEffects {
  #actions = inject(Actions);
  #formService = inject(PackageEditFormService);
  public readonly resetForm$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(PackageEditFormActions.resetPackageForm),
        tap(() => {
          this.#formService.form.reset();
          this.#formService.resetBenefits();
          this.#formService.form.enable();
        }),
      ),
    { dispatch: false },
  );
  public readonly addBenefit$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(PackageEditFormActions.addBenefit),
        tap(() => {
          const form = this.#formService.form;

          if (form.controls.benefits.controls.length >= 5) {
            return;
          }

          form.controls.benefits.push(
            new FormControl('', {
              nonNullable: true,
              validators: Validators.maxLength(20),
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  public readonly removeBenefit$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(PackageEditFormActions.removeBenefit),
        tap(({ index }) => {
          const benefitsControl = this.#formService.form.controls.benefits;

          if (!benefitsControl.at(index)) {
            return;
          }

          benefitsControl.removeAt(index);
          this.#formService.form.markAllAsTouched();
          benefitsControl.markAsDirty();
        }),
      ),
    { dispatch: false },
  );
  #packageApi = inject(PackageApiService);
  public readonly load$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(PackageEditFormActions.loadPackage),
        exhaustMap(({ id }) =>
          this.#packageApi.getPackageDetails(id).pipe(
            tap((data) => {
              if (data) {
                this.#formService.form.enable();
                this.#formService.form.reset(data);

                if (data.benefits.length > 0) {
                  const controls = data.benefits.map(
                    (x) =>
                      new FormControl(x, {
                        nonNullable: true,
                        validators: Validators.maxLength(20),
                      }),
                  );
                  const benefitsFormArray = new FormArray(controls);

                  this.#formService.form.setControl(
                    'benefits',
                    benefitsFormArray,
                  );
                }
              }
            }),
          ),
        ),
      ),
    { dispatch: false },
  );
  public readonly save$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(PackageEditFormActions.savePackage),
        map(({ id, locationId }) => ({
          form: this.#formService.form,
          id,
          locationId,
        })),
        filter(({ form }) => {
          if (form.invalid) {
            form.markAllAsTouched();
          }

          return form.valid;
        }),
        tap(({ form }) => form.disable()),
        exhaustMap(({ form, id, locationId }) =>
          this.#packageApi.updatePackage(id, form.getRawValue()).pipe(
            withLatestFrom(
              this.#globalStore.select(appSelectors.selectLanguage),
            ),
            tap(() => form.markAsPristine()),
            finalize(() => form.enable()),
            tap(() => this.showSaveSuccess()),
            tap(([_, lang]) => {
              const callbackUrl = history.state?.['callback'];

              if (callbackUrl) {
                this.#router.navigate(callbackUrl);
                return;
              }

              this.#router.navigate([lang, 'package', locationId, id]);
            }),
            catchError(() => {
              this.showSaveError();
              return EMPTY;
            }),
            map(() => form),
          ),
        ),
        tap((form) => form.enable()),
      ),
    { dispatch: false },
  );
  #toastService = inject(ToastService);
  #globalStore = inject(Store<GlobalState>);
  #router = inject(Router);
  public readonly create$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(PackageEditFormActions.createPackage),
        map(({ locationId }) => ({
          form: this.#formService.form,
          locationId,
        })),
        filter(({ form }) => {
          if (form.invalid) {
            form.markAllAsTouched();
          }

          return form.valid;
        }),
        tap(({ form }) => form.disable()),
        exhaustMap(({ locationId, form }) =>
          this.#packageApi
            .createPackage({ ...form.getRawValue(), locationId })
            .pipe(
              withLatestFrom(
                this.#globalStore.select(appSelectors.selectLanguage),
              ),
              tap(() => this.showSaveSuccess()),
              tap(([id, lang]) => {
                const callbackUrl = history.state?.['callback'];

                if (callbackUrl) {
                  this.#router.navigate(callbackUrl);
                  return;
                }

                this.#router.navigate([lang, 'package', locationId, id]);
              }),
              catchError((error) => {
                this.showSaveError();
                console.error(error);
                return EMPTY;
              }),
              map(() => form),
            ),
        ),
        tap((form) => form.enable()),
      ),
    { dispatch: false },
  );
  #location = inject(Location);
  goBack$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(PackageEditFormActions.back),
        map(() => {
          this.#location.back();
        }),
      ),
    { dispatch: false },
  );

  private showSaveSuccess(): void {
    this.#toastService.showSuccess(
      'izzo.admin.shared.success.changesSaved.title',
      'izzo.admin.shared.success.changesSaved.message',
    );
  }

  private showSaveError(): void {
    this.#toastService.showError(
      'izzo.admin.shared.errors.save.title',
      'izzo.admin.shared.errors.save.message',
    );
  }
}
