import {
  APP_INITIALIZER,
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CreateListEffects } from './features/create-list/store/create-list.effects';
import { ListReadOnlyEffects } from './features/list-readOnly/store/list-readOnly.effects';
import { MyListEffects } from './features/my-list/store/my-list.effects';
import { OrbitEffects } from './features/orbit/store/orbit.effects';
import * as Sentry from '@sentry/angular';

import { DialogModule } from '@angular/cdk/dialog';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrokenBusinessRuleInterceptor } from './core/business-rule/broken-business-rule.interceptor';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { appReducer } from './core/store/app';
import { AppEffects } from './core/store/app/app.effects';
import { clubEditReducer } from './features/club/club-edit/store';
import { ClubEditFormEffects } from './features/club/club-edit/store/club-edit-form.effects';
import { clubListReducer } from './features/club/club-list/store';
import { ClubListEffects } from './features/club/club-list/store/club-list.effects';
import { createListReducer } from './features/create-list/store';
import { createLoungeReducer } from './features/create-lounge/store';
import { CreateLoungeEffects } from './features/create-lounge/store/create-lounge.effects';
import { clientCreateFormReducer } from './features/crm/create/store';
import { ClientCreateFormEffects } from './features/crm/create/store/client-create-form.effects';
import { ClientEditFormEffects } from './features/crm/edit/store/client-edit-form.effects';
import { crmOverviewReducer } from './features/crm/overview/store';
import { CrmOverviewEffects } from './features/crm/overview/store/crm-overview.effects';
import { editEventReducer } from './features/edit-event/store';
import { EditEventEffects } from './features/edit-event/store/edit-event.effects';
import { editListReducer } from './features/edit-list/store';
import { EditListEffects } from './features/edit-list/store/edit-list.effects';
import { editLoungeReducer } from './features/edit-lounge/store';
import { EditLoungeEffects } from './features/edit-lounge/store/edit-lounge.effects';
import { listReadOnlyReducer } from './features/list-readOnly/store';
import { myEmployeesReducer } from './features/my-employees/store';
import { MyEmployeesEffects } from './features/my-employees/store/my-employees.effects';
import { myEventsReducer } from './features/my-events/store';
import { MyEventsEffects } from './features/my-events/store/my-events.effects';
import { myListReducer } from './features/my-list/store';
import { orbitReducer } from './features/orbit/store';
import { PackageEditFormEffects } from './features/package/package-edit/store/package-edit-form.effects';
import { reservationReducer } from './features/reservation/store';
import { ReservationEffects } from './features/reservation/store/reservation.effects';
import { roleManagementReducer } from './features/role-management/store';
import { RoleManagementEffects } from './features/role-management/store/role-management.effects';
import { uploadEventReducer } from './features/upload-event/store';
import { UploadEventEffects } from './features/upload-event/store/upload-event.effects';
import { SharedModule } from './shared/shared.module';
import { Router } from '@angular/router';

export function HttpLoaderFactory(httpClient: HttpClient) {
  if (environment.production) {
    return new TranslateHttpLoader(
      httpClient,
      `https://cdn.simplelocalize.io/d11d680caab0426da1d10c9f718260ba/_latest/`,
      '',
    );
  }

  const baseHref = document.querySelector('base')!.getAttribute('href');
  return new TranslateHttpLoader(
    httpClient,
    `${baseHref}assets/i18n/`,
    '.json',
  );
}

const environmentModules: ModuleWithProviders<StoreDevtoolsModule>[] =
  !environment.production
    ? [StoreDevtoolsModule.instrument({ maxAge: 80 })]
    : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot({
      router: routerReducer,
      app: appReducer,
      uploadEvent: uploadEventReducer,
      editEvent: editEventReducer,
      myEvents: myEventsReducer,
      crmOverview: crmOverviewReducer,
      crmClientCreate: clientCreateFormReducer,
      createList: createListReducer,
      editList: editListReducer,
      orbit: orbitReducer,
      myList: myListReducer,
      listReadOnly: listReadOnlyReducer,
      clubList: clubListReducer,
      clubEdit: clubEditReducer,
      myEmployees: myEmployeesReducer,
      roleManagement: roleManagementReducer,
      createLounge: createLoungeReducer,
      editLounge: editLoungeReducer,
      reservation: reservationReducer,
    }),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    EffectsModule.forRoot([
      AppEffects,
      UploadEventEffects,
      EditEventEffects,
      MyEventsEffects,
      CrmOverviewEffects,
      ClientEditFormEffects,
      ClientCreateFormEffects,
      PackageEditFormEffects,
      CreateListEffects,
      OrbitEffects,
      MyListEffects,
      EditListEffects,
      ListReadOnlyEffects,
      ClubListEffects,
      ClubEditFormEffects,
      MyEmployeesEffects,
      RoleManagementEffects,
      CreateLoungeEffects,
      EditLoungeEffects,
      ReservationEffects,
    ]),

    ...environmentModules,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrokenBusinessRuleInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
