import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  EMPTY,
  catchError,
  exhaustMap,
  filter,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ErrorHandlingService } from 'src/app/core/business-rule/error-handling.service';
import { FileDownloadService } from 'src/app/core/services/file-download.service';
import { GlobalState } from 'src/app/core/store';
import { appSelectors } from 'src/app/core/store/app';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { CrmService } from '../../crm.service';
import { CrmOverviewContainerActions } from './actions';

@Injectable({
  providedIn: 'root',
})
export class CrmOverviewEffects {
  #actions = inject(Actions);
  #router = inject(Router);
  #crmService = inject(CrmService);
  #fileDownloadService = inject(FileDownloadService);
  #translate = inject(TranslateService);
  #globalStore = inject(Store<GlobalState>);
  #toastService = inject(ToastService);
  #errorHandlingService = inject(ErrorHandlingService);

  public readonly search$ = createEffect(() =>
    this.#actions.pipe(
      ofType(CrmOverviewContainerActions.search),
      exhaustMap(({ searchText }) =>
        this.#crmService.searchClients(searchText).pipe(
          map((data) => CrmOverviewContainerActions.searchSuccess({ data })),
          catchError((error) => {
            this.#errorHandlingService.handleError(
              error,
              'izzo.admin.crm.errors',
            );

            return EMPTY;
          }),
        ),
      ),
    ),
  );

  public readonly importCsv$ = createEffect(() =>
    this.#actions.pipe(
      ofType(CrmOverviewContainerActions.importCsv),
      map((x) => ({
        file: x.file,
        acceptedTypes: ['application/vnd.ms-excel', 'text/csv'],
      })),
      filter(
        (x) =>
          !!x.file &&
          x.acceptedTypes.includes(x.file.type) &&
          x.file.name.endsWith('.csv'),
      ),
      tap(() =>
        this.#toastService.showInfo(
          'izzo.admin.shared.info.csvImportStarted.title',
          'izzo.admin.shared.info.csvImportStarted.message',
        ),
      ),
      exhaustMap((x) =>
        this.#crmService.importCsv(x.file).pipe(
          tap((result) => {
            if (result.status === 'completed') {
              this.#toastService.showSuccess(
                'izzo.admin.shared.success.csvImport.title',
                'izzo.admin.shared.success.csvImport.message',
              );
            } else {
              this.#toastService.showError(result.details!);
              console.error(result.details);
            }
          }),
        ),
      ),
      map(() => CrmOverviewContainerActions.search({ searchText: '' })),
    ),
  );

  public readonly exportCsv$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(CrmOverviewContainerActions.exportCsv),
        tap(() =>
          this.#toastService.showInfo(
            'izzo.admin.shared.info.csvExportStarted.title',
            'izzo.admin.shared.info.csvExportStarted.message',
          ),
        ),
        exhaustMap(() =>
          forkJoin({
            fileName: this.#translate.get('izzo.admin.crm.clientlist').pipe(
              map((x) => ({
                name: x,
                today: new Date().toISOString().split('T')[0],
              })),
              map(({ name, today }) => `${name}_${today}.csv`),
            ),
            response: this.#crmService.exportCsv(),
          }).pipe(
            catchError(() => {
              this.#toastService.showError(
                'izzo.admin.shared.errors.csvExport',
                'izzo.admin.shared.errors.unexpected',
              );
              return EMPTY;
            }),
            filter((x) => !!x.response.body),
            tap((x) =>
              this.#fileDownloadService.downloadBlob(
                x.response.body!,
                x.fileName,
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  public readonly getCsvTemplateClick$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(CrmOverviewContainerActions.getCsvTemplateClick),
        tap(() =>
          this.#toastService.showInfo(
            'izzo.admin.shared.info.downloadStarted.title',
            'izzo.admin.shared.info.downloadStarted.message',
          ),
        ),
        exhaustMap(() =>
          forkJoin({
            fileName: this.#translate.get('izzo.admin.crm.templateName'),
            response: this.#crmService.getCsvTemplate(),
          }).pipe(
            catchError(() => {
              this.#toastService.showError(
                'izzo.admin.shared.errors.csvDownload',
                'izzo.admin.shared.errors.unexpected',
              );
              return EMPTY;
            }),
            filter((x) => !!x.response.body),
            tap((x) =>
              this.#fileDownloadService.downloadBlob(
                x.response.body!,
                x.fileName,
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  public readonly createClient$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(CrmOverviewContainerActions.createClient),
        withLatestFrom(this.#globalStore.select(appSelectors.selectLanguage)),
        tap(([_, lang]) =>
          this.#router.navigate([lang, 'crm', 'client', 'new']),
        ),
      ),
    { dispatch: false },
  );

  public readonly editClient$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(CrmOverviewContainerActions.editClient),
        withLatestFrom(this.#globalStore.select(appSelectors.selectLanguage)),
        tap(([{ id }, lang]) =>
          this.#router.navigate([lang, 'crm', 'client', id]),
        ),
      ),
    { dispatch: false },
  );

  public readonly deleteClient$ = createEffect(() =>
    this.#actions.pipe(
      ofType(CrmOverviewContainerActions.deleteClient),
      switchMap(({ id }) =>
        this.#crmService.deleteClient(id).pipe(
          map(() => CrmOverviewContainerActions.deleteClientSuccess()),
          catchError(() => {
            this.#toastService.showError('izzo.admin.crm.deleteError');
            return of(CrmOverviewContainerActions.search({ searchText: '' }));
          }),
        ),
      ),
    ),
  );

  deleteClientSuccess$ = createEffect(() =>
    this.#actions.pipe(
      ofType(CrmOverviewContainerActions.deleteClientSuccess),
      tap(() => {
        this.#toastService.showSuccess('izzo.admin.crm.deleteSuccess');
      }),
      map(() => CrmOverviewContainerActions.search({ searchText: '' })),
    ),
  );
}
