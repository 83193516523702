import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  EMPTY,
  exhaustMap,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { CrmService } from '../../crm.service';
import { ClientEditFormService } from '../client-edit-form.service';
import { ClientEditFormActions } from './actions';
import { Location } from '@angular/common';
import { ErrorHandlingService } from 'src/app/core/business-rule/error-handling.service';

@Injectable()
export class ClientEditFormEffects {
  #actions = inject(Actions);
  #formService = inject(ClientEditFormService);
  #crmService = inject(CrmService);
  #errorHandlingService = inject(ErrorHandlingService);
  public readonly load$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClientEditFormActions.loadClient),
        exhaustMap(({ id }) =>
          this.#crmService.getClientDetails(id).pipe(
            tap((data) => {
              this.#formService.form.patchValue({
                firstName: data?.firstName || '',
                lastName: data?.lastName || '',
                phoneNumber: data?.phoneNumber || '',
                email: data?.email || '',
                street: data?.street || '',
                plz: {
                  id: 0,
                  plz: data?.zipCode || '',
                  province: data?.city || '',
                },
              });
            }),
            tap(() => this.#formService.form.markAsPristine()),
          ),
        ),
      ),
    { dispatch: false },
  );
  #toastService = inject(ToastService);
  public readonly save$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClientEditFormActions.saveClient),
        map(({ id }) => ({ form: this.#formService.form, id })),
        filter(({ form }) => {
          if (form.invalid) {
            this.#formService.form.markAllAsTouched();
          }

          return form.valid;
        }),
        tap(({ form }) => form.disable()),
        tap((form) => ({
          form: {
            firstName: form.form.controls.firstName.value,
            lastName: form.form.controls.lastName.value,
            phoneNumber: form.form.controls.phoneNumber.value,
            email: form.form.controls.email.value,
            street: form.form.controls.street.toString() ?? '',
            plz: form.form.controls.plz.getRawValue()?.plz.toString() ?? '',
            province:
              form.form.controls.plz.getRawValue()?.province.toString() ?? '',
          },
          id: form.id,
        })),
        switchMap((form) => {
          return this.#crmService
            .updateClient(form.id, {
              id: form.id,
              firstName: form.form.controls.firstName.value,
              lastName: form.form.controls.lastName.value,
              phoneNumber: form.form.controls.phoneNumber.value,
              email: form.form.controls.email.value,
              street: form.form.controls.street.value,
              zipCode:
                form.form.controls.plz.getRawValue()?.plz!.toString() ?? '',
              city:
                form.form.controls.plz.getRawValue()?.province!.toString() ??
                '',
              place: form.form.controls.plz.value!,
            })
            .pipe(
              tap(() => form.form.markAsPristine()),
              finalize(() => form.form.enable()),
              tap(() =>
                this.#toastService.showSuccess(
                  'izzo.admin.shared.success.changesSaved.title',
                  'izzo.admin.shared.success.changesSaved.message',
                ),
              ),
              catchError((error) => {
                this.#errorHandlingService.handleError(
                  error,
                  'izzo.admin.crm.errors',
                );
                return EMPTY;
              }),
            );
        }),
      ),
    { dispatch: false },
  );
  #location = inject(Location);
  goBack$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClientEditFormActions.backClicked),
        map(() => {
          this.#location.back();
        }),
      ),
    { dispatch: false },
  );
}
