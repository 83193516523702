import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Worker } from '../models/app.models';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  constructor(private readonly http: HttpClient) {}

  searchEmployees(searchText: string) {
    return this.http.get<Worker[]>(
      `${environment.api}/worker?search=${searchText}`,
    );
  }

  createClient(employee: Worker) {
    return this.http.post<number>(`${environment.api}/worker/add`, employee);
  }

  getEmployeeDetails(id: number) {
    return this.http.get<Worker>(`${environment.api}/worker/${id}`);
  }

  updateEmployee(id: number, client: Omit<Worker, 'id'>) {
    return this.http.put<void>(`${environment.api}/worker/${id}`, client, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  deleteClient(id: number) {
    return this.http.delete<void>(`${environment.api}/worker/${id}`);
  }

  resetPassword(id: number) {
    return this.http.put<void>(
      `${environment.api}/worker/reset-password/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
