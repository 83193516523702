import { createFeature, createReducer, on } from '@ngrx/store';
import { ClubEditFormActions } from './actions';
import { ClubEditState } from './club-edit.state';

export const initialState: Readonly<ClubEditState> = {
  currentStep: 'clubInformation',
  packages: [],
  lounges: [],
  locations: [],
};

export const clubEditReducers = createReducer(
  initialState,
  on(ClubEditFormActions.loadClubSuccess, (state, { data }) => {
    const locations =
      data.location.id > 0
        ? [
            {
              ...data.location,
              id: data.location.id,
              coordinates: '',
              street: data.location.street,
              place: data.location.place,
              region: data.location.region,
              packages: data.packages,
            },
            ...state.locations,
          ]
        : [...state.locations];

    return {
      ...state,
      locations,
      packages: data.packages,
      lounges: data.lounges,
    };
  }),
  on(ClubEditFormActions.stepBackClicked, (state) => {
    switch (state.currentStep) {
      case 'contactData':
        return {
          ...state,
          currentStep: 'clubInformation',
        };
      case 'socials':
        return {
          ...state,
          currentStep: 'contactData',
        };
      case 'logo':
        return {
          ...state,
          currentStep: 'socials',
        };
      default:
        return state;
    }
  }),
  on(ClubEditFormActions.clubInformationValid, (state) => ({
    ...state,
    currentStep: 'contactData',
  })),
  on(ClubEditFormActions.contactDataValid, (state) => ({
    ...state,
    currentStep: 'socials',
  })),
  on(ClubEditFormActions.socialsValid, (state) => ({
    ...state,
    currentStep: 'logo',
  })),
  on(ClubEditFormActions.selectTab, (state, { currentStep }) => ({
    ...state,
    currentStep,
  })),
  on(ClubEditFormActions.deletePackageSuccess, (state, { packageId }) => {
    const packages = state.packages.filter((x) => x.id !== packageId);

    return {
      ...state,
      packages: [...packages],
    };
  }),
  on(ClubEditFormActions.deleteLoungeSuccess, (state, { loungeId }) => {
    const lounges = state.lounges.filter((x) => x.id !== loungeId);

    return {
      ...state,
      lounges: [...lounges],
    };
  }),
);

export const clubEditFeature = createFeature({
  name: 'clubEdit',
  reducer: clubEditReducers,
});
