import { Dialog } from '@angular/cdk/dialog';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  EMPTY,
  exhaustMap,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ListService } from 'src/app/core/services/list.service';
import { LoungeService } from 'src/app/core/services/lounge.service';
import { ReservationService } from 'src/app/core/services/reservation.service';
import { MyEventsContainerActions } from 'src/app/core/store/actions/index';
import { appSelectors } from 'src/app/core/store/app';
import {
  ReservationRequestComponent,
  ReservationRequestDialogResult,
} from 'src/app/shared/components/reservation-request/reservation-request.component';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { orbitSelectors } from '.';
import { FormService } from '../../../core/services/form.service';
import { GlobalState } from '../../../core/store';
import { PackageApiService } from '../../package/package-api.service';
import { OrbitContainerActions } from '../store/index';
import { OrbitState } from './orbit.state';

@Injectable({
  providedIn: 'root',
})
export class OrbitEffects {
  createListClicked$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.createListClicked),
        withLatestFrom(
          this.store.select(orbitSelectors.selectEventInformation),
        ),
      ),
    { dispatch: false },
  );
  orbitOpened$ = createEffect(() =>
    this.actions.pipe(
      ofType(MyEventsContainerActions.openOrbitClicked),
      switchMap(({ event }) => this.listService.getListsByEventId(event!.id!)),
      map((lists) =>
        OrbitContainerActions.orbitOpened({
          lists,
        }),
      ),
    ),
  );
  initOrbit$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrbitContainerActions.initOrbit),
      exhaustMap(({ eventId }) =>
        forkJoin([
          this.reservationService.getAllReservationRequestsFromEvent(eventId),
          this.loungeService.getAllLoungesFromEvent(eventId),
        ]),
      ),
      map(([reservations, lounges]) =>
        OrbitContainerActions.initOrbitSuccess({
          reservations,
          lounges,
        }),
      ),
    ),
  );
  reloadOrbit$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrbitContainerActions.checkInLoungeSuccessful),
      withLatestFrom(this.store.select(orbitSelectors.selectEventInformation)),
      exhaustMap(([_, { id }]) =>
        forkJoin([
          this.reservationService.getAllReservationRequestsFromEvent(id!),
          this.loungeService.getAllLoungesFromEvent(id!),
        ]),
      ),
      map(([reservations, lounges]) =>
        OrbitContainerActions.initOrbitSuccess({
          reservations,
          lounges,
        }),
      ),
    ),
  );
  deleteList$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrbitContainerActions.deleteListClicked),
      switchMap(({ list }) => {
        return this.listService.deleteList(list!.id!).pipe(
          map(() => OrbitContainerActions.deleteListSuccessful()),
          catchError((error) => of(OrbitContainerActions.deleteListFailed())),
        );
      }),
    ),
  );
  deleteListFailed$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrbitContainerActions.deleteListFailed),
      tap(() =>
        this.toastService.showError(
          'izzo.admin.list.delete.error.title',
          'izzo.admin.list.delete.error.text',
        ),
      ),
    ),
  );
  deleteListSuccessful$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrbitContainerActions.deleteListSuccessful),
      withLatestFrom(this.store.select(orbitSelectors.selectEventInformation)),
      tap(() => {
        this.toastService.showSuccess(
          'izzo.admin.list.delete.success.title',
          'izzo.admin.list.delete.success.text',
        );
      }),
      switchMap(([_, eventInformation]) =>
        this.listService.getListsByEventId(eventInformation!.id!),
      ),
      map((lists) => OrbitContainerActions.orbitOpened({ lists })),
    ),
  );
  navigateToAllListsPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.openAllListsClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          this.router.navigate([language, 'allLists', 'event', event.id]);
        }),
      ),
    { dispatch: false },
  );
  navigateToAllInviteesPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.openAllInviteesClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          this.router.navigate([language, 'allInvitees', 'event', event.id]);
        }),
      ),
    { dispatch: false },
  );
  navigateToAllReservationsPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.openAllReservationsClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          this.router.navigate([
            language,
            'allReservations',
            'event',
            event.id,
          ]);
        }),
      ),
    { dispatch: false },
  );
  navigateToAllLoungesPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.openAllLoungesClicked),
        withLatestFrom(this.store.select(appSelectors.selectLanguage)),
        tap(([{ event }, language]) => {
          this.router.navigate([language, 'allLounges', 'event', event.id]);
        }),
      ),
    { dispatch: false },
  );
  deleteLounge$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrbitContainerActions.deleteLoungeClicked),
      switchMap(({ lounge }) => {
        return this.loungeService.deleteLounge(lounge!.id!).pipe(
          map(() =>
            OrbitContainerActions.deleteLoungeSuccessful({
              loungeId: lounge.id!,
            }),
          ),
          catchError((error) => of(OrbitContainerActions.deleteLoungeFailed())),
        );
      }),
    ),
  );
  checkInLounge$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrbitContainerActions.checkInLoungeClicked),
      switchMap(({ lounge }) => {
        return this.loungeService.checkInLounge(lounge).pipe(
          map(() => OrbitContainerActions.checkInLoungeSuccessful()),
          catchError((error) =>
            of(OrbitContainerActions.checkInLoungeFailed()),
          ),
        );
      }),
    ),
  );
  checkInLoungeSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.checkInLoungeSuccessful),
        tap(() => {
          this.toastService.showSuccess(
            'izzo.admin.orbit.lounge.checkedInTitle',
            'izzo.admin.orbit.lounge.checkedInText',
          );
        }),
      ),
    { dispatch: false },
  );
  addPackage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.addPackage),
        withLatestFrom(
          this.store.select(orbitSelectors.selectEventInformation),
          this.store.select(appSelectors.selectLanguage),
        ),
        tap(([_, event, lang]) =>
          this.router.navigate([lang, 'package', event.location, -1], {
            state: { callback: [lang, 'orbit', 'event', event.id] },
          }),
        ),
      ),
    { dispatch: false },
  );
  editPackage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.editPackage),
        withLatestFrom(
          this.store.select(orbitSelectors.selectEventInformation),
          this.store.select(appSelectors.selectLanguage),
        ),
        tap(([{ packageId }, event, lang]) =>
          this.router.navigate([lang, 'package', event.location, packageId], {
            state: { callback: [lang, 'orbit', 'event', event.id] },
          }),
        ),
      ),
    { dispatch: false },
  );
  deletePackage$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrbitContainerActions.deletePackage),
      switchMap(({ packageId }) => {
        return this.packageService
          .deletePackage(packageId)
          .pipe(
            map(() =>
              OrbitContainerActions.deletePackageSuccess({ packageId }),
            ),
          );
      }),
    ),
  );
  deletePackageSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.deletePackageSuccess),
        tap(() => {
          this.toastService.showSuccess(
            'izzo.admin.package.deletePackage.success',
            'izzo.admin.package.deletePackage.success.text',
          );
        }),
      ),
    { dispatch: false },
  );
  navigateReservationRequestPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrbitContainerActions.openReservationRequestClicked),
      withLatestFrom(this.store.select((x) => x)),
      // tap(([{ reservation }, language]) => {
      //   this.router.navigate([language, 'reservation', reservation.id]);
      // }),
      switchMap(([{ reservation }, state]) =>
        this.dialog
          .open<ReservationRequestDialogResult>(ReservationRequestComponent, {
            maxHeight: '90vh',
            height: '90vh',
            width: '800px',
            data: {
              reservation,
              event: state.orbit.eventInformation,
              lounges: state.orbit.lounges,
              form: this.formService.getReservationRequestFormGroup(),
            },
          })
          .closed.pipe(
            exhaustMap((result) => {
              if (!result) {
                return EMPTY;
              }

              if (result.type === 'cancel') {
                return this.reservationService
                  .cancelReservation(reservation.id!)
                  .pipe(
                    tap(() => {
                      this.toastService.showSuccess(
                        'izzo.admin.orbit.reservation.cancel.success',
                        'izzo.admin.orbit.reservation.cancel.success.text',
                      );
                    }),
                    map(() =>
                      OrbitContainerActions.cancelReservationClicked({
                        reservation,
                      }),
                    ),
                  );
              }

              const values = this.formService
                .getReservationRequestFormGroup()
                .getRawValue();

              return this.reservationService
                .assignReservation({
                  reservationId: reservation.id!,
                  internComment: values.internComment ?? '',
                  message: values.message ?? '',
                  loungeId: values.lounge!,
                })
                .pipe(
                  tap(() => {
                    this.toastService.showSuccess(
                      'izzo.admin.orbit.reservation.assign.success',
                      'izzo.admin.orbit.reservation.assign.success.text',
                    );
                  }),
                  map(() =>
                    OrbitContainerActions.assignReservationClicked({
                      reservation,
                    }),
                  ),
                );
            }),
          ),
      ),
    ),
  );
  assignReservationClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrbitContainerActions.assignReservationClicked),
      withLatestFrom(this.store.select(orbitSelectors.selectEventInformation)),
      map(([reservation, event]) =>
        OrbitContainerActions.initOrbit({
          eventId: event.id!,
        }),
      ),
    ),
  );
  assignReservationSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.assignReservationSuccessful),
        tap(() => {
          this.toastService.showSuccess(
            'izzo.admin.orbit.reservation.assign.success',
            'izzo.admin.orbit.reservation.assign.success.text',
          );
        }),
      ),
    { dispatch: false },
  );
  cancelReservationSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrbitContainerActions.cancelReservationSuccessful),
        tap(() => {
          this.toastService.showSuccess(
            'izzo.admin.orbit.reservation.cancel.success',
            'izzo.admin.orbit.reservation.cancel.success.text',
          );
        }),
      ),
    { dispatch: false },
  );
  #toastService = inject(ToastService);

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<GlobalState>,
    private readonly orbitStore: Store<OrbitState>,
    private readonly formService: FormService,
    private readonly router: Router,
    private readonly listService: ListService,
    private readonly loungeService: LoungeService,
    private readonly packageService: PackageApiService,
    private readonly dialog: Dialog,
    private readonly toastService: ToastService,
    private readonly reservationService: ReservationService,
  ) {}
}
