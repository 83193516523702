import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GlobalState } from 'src/app/core/store';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { catchError, exhaustMap, filter, finalize, map, of, tap } from 'rxjs';
import { Location } from '@angular/common';
import { RoleManagementContainerActions } from './actions';
import { RoleService } from '../../../core/services/role.service';
import { EmployeeService } from '../../../core/services/employee.service';
import { RoleManagementService } from '../role-management.service';

@Injectable({
  providedIn: 'root',
})
export class RoleManagementEffects {
  goBack$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(RoleManagementContainerActions.backClicked),
        map(() => {
          this.location.back();
        }),
      ),
    { dispatch: false },
  );

  search$ = createEffect(() =>
    this.actions.pipe(
      ofType(RoleManagementContainerActions.search),
      exhaustMap(({ searchText }) =>
        this.roleService
          .searchWorkerRole(searchText)
          .pipe(
            map((data) =>
              RoleManagementContainerActions.searchSuccess({ data }),
            ),
          ),
      ),
    ),
  );

  searchInitial$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        RoleManagementContainerActions.roleManagementInitialized,
        RoleManagementContainerActions.addRoleDone,
      ),
      exhaustMap(() => {
        const searchTextParam = this.router.parseUrl(this.router.url)
          .queryParams['search'];

        if (searchTextParam) {
          this.roleManagementService.searchForm.patchValue({
            search: searchTextParam,
          });
        }

        return this.roleService
          .searchWorkerRole(searchTextParam ?? '')
          .pipe(
            map((data) =>
              RoleManagementContainerActions.searchSuccess({ data }),
            ),
          );
      }),
    ),
  );

  getRolesInitial$ = createEffect(() =>
    this.actions.pipe(
      ofType(RoleManagementContainerActions.roleManagementInitialized),
      exhaustMap(() =>
        this.roleService
          .getAllRoles()
          .pipe(
            map((data) =>
              RoleManagementContainerActions.getRolesSuccess({ data }),
            ),
          ),
      ),
    ),
  );

  getWorkersInitial$ = createEffect(() =>
    this.actions.pipe(
      ofType(RoleManagementContainerActions.roleManagementInitialized),
      exhaustMap(() =>
        this.employeeService
          .searchEmployees('')
          .pipe(
            map((data) =>
              RoleManagementContainerActions.getWorkersSuccess({ data }),
            ),
          ),
      ),
    ),
  );

  addRoleToEmployee$ = createEffect(() =>
    this.actions.pipe(
      ofType(RoleManagementContainerActions.addClicked),
      map(() => ({ form: this.roleManagementService.form })),
      filter(({ form }) => {
        if (form.invalid) {
          this.roleManagementService.form.markAllAsTouched();
        }

        return form.valid;
      }),
      tap(({ form }) => form.disable()),
      exhaustMap(({ form }) =>
        this.roleService.addRoleToEmployee(form.getRawValue()).pipe(
          map((response) => {
            if (response.successful) {
              this.toastService.showSuccess(
                'izzo.admin.shared.success.changesSaved.title',
                'izzo.admin.shared.success.changesSaved.message',
              );
              return RoleManagementContainerActions.addRoleDone();
            } else {
              this.toastService.showError(
                'izzo.admin.shared.errors.save.title',
                `izzo.admin.shared.errors.save.alreadyHasRole`,
              );
              return RoleManagementContainerActions.addRoleFailed();
            }
          }),
          tap(() => form.markAsPristine()),
          finalize(() => {
            form.enable();
          }),
          catchError(() => {
            this.toastService.showError(
              'izzo.admin.shared.errors.save.title',
              'izzo.admin.shared.errors.save.message',
            );
            return of(RoleManagementContainerActions.addRoleFailed());
          }),
        ),
      ),
    ),
  );

  deleteWorkerRole$ = createEffect(() =>
    this.actions.pipe(
      ofType(RoleManagementContainerActions.deleteClicked),
      exhaustMap(({ workerRole }) =>
        this.roleService.deleteWorkerRole(workerRole).pipe(
          map(() => RoleManagementContainerActions.search({ searchText: '' })),
          tap(() =>
            this.toastService.showSuccess(
              'izzo.admin.roleManagement.deleteSuccess',
            ),
          ),
          catchError(() => {
            this.toastService.showError(
              'izzo.admin.roleManagement.deleteError',
            );
            return of(
              RoleManagementContainerActions.search({ searchText: '' }),
            );
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<GlobalState>,
    private readonly router: Router,
    private location: Location,
    private readonly toastService: ToastService,
    private readonly roleService: RoleService,
    private readonly employeeService: EmployeeService,
    private readonly roleManagementService: RoleManagementService,
  ) {}
}
