import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { LoungeService } from 'src/app/core/services/lounge.service';
import { appSelectors } from 'src/app/core/store/app';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { FormService } from '../../../core/services/form.service';
import { GlobalState } from '../../../core/store';
import { OrbitState } from '../../orbit/store/orbit.state';
import {
  CreateLoungeContainerActions,
  CreateLoungeEffectsActions,
  createLoungeSelectors,
} from './index';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CreateLoungeEffects {
  createLounge$ = createEffect(() =>
    this.actions.pipe(
      ofType(CreateLoungeContainerActions.createLoungeClicked),
      withLatestFrom(
        this.orbitStore.select(createLoungeSelectors.selectCreateLoungeState),
      ),
      switchMap(([_, state]) => {
        const loungeForm = this.formService.getCreateLoungeFormGroup();
        loungeForm.markAllAsTouched();
        if (loungeForm.valid) {
          return this.loungeService
            .createLounge({
              name: loungeForm.controls.name.value!,
              numberSeats: loungeForm.controls.numberSeats.value!,
              loungeType: {
                id: loungeForm.controls.loungeType.value!,
                name: '',
              },
              comment: loungeForm.controls.comment.value!,
              event: state.eventInformation,
              reservation: null,
              loungeStatus: 'available',
              locationId:
                state.eventInformation.id === 0 ? state.locationId : 0,
              packages: [],
              location: {
                id: state.locationId!,
                coordinates: '',
                name: '',
                packages: [],
                place: {
                  id: 0,
                  plz: '',
                  province: '',
                },
                street: '',
              },
            })
            .pipe(
              map(() => CreateLoungeEffectsActions.createLoungeSuccessful()),
              catchError((_error) => [
                CreateLoungeEffectsActions.createLoungeUnsuccessful(),
              ]),
            );
        } else {
          return of(CreateLoungeEffectsActions.createLoungeFormInvalid());
        }
      }),
    ),
  );
  backToOpenOrbitPage$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(CreateLoungeContainerActions.backClicked),
        withLatestFrom(
          this.store.select(appSelectors.selectLanguage),
          this.orbitStore.select(createLoungeSelectors.selectCreateLoungeState),
        ),
        tap(([_, language, state]) => {
          this.formService.getCreateLoungeFormGroup().reset({
            name: '',
            loungeType: 0,
            comment: '',
            numberSeats: 0,
          });

          this.location.back();
        }),
      ),
    { dispatch: false },
  );
  #toastService = inject(ToastService);
  uploadSuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(CreateLoungeEffectsActions.createLoungeSuccessful),
        tap(() => {
          this.#toastService.showSuccess(
            'izzo.admin.orbit.lounge.create.success',
            'izzo.admin.orbit.lounge.create.success.text',
          );

          this.formService.getCreateLoungeFormGroup().reset({
            name: '',
            loungeType: 0,
            comment: '',
            numberSeats: 0,
          });
        }),
        withLatestFrom(
          this.store,
          this.store.select(createLoungeSelectors.selectEventInformation),
        ),
        map(([_, store, event]) => {
          const callbackUrl = history.state?.['callback'];

          if (callbackUrl) {
            return this.router.navigate(callbackUrl);
          }

          return this.router.navigate([
            store.app.language,
            'orbit',
            'event',
            event.id,
          ]);
        }),
      ),
    { dispatch: false },
  );

  uploadUnsuccessful$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(CreateLoungeEffectsActions.createLoungeUnsuccessful),
        tap(() => {
          this.#toastService.showError(
            'izzo.admin.orbit.lounge.create.error',
            'izzo.admin.orbit.lounge.create.error.text',
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<GlobalState>,
    private readonly orbitStore: Store<OrbitState>,
    private readonly formService: FormService,
    private readonly loungeService: LoungeService,
    private readonly router: Router,
    private readonly location: Location,
  ) {}
}
