import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, exhaustMap, map, tap, withLatestFrom } from 'rxjs';
import { GlobalState } from 'src/app/core/store';
import { appSelectors } from 'src/app/core/store/app';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ClubApiService } from '../../club-api.service';
import { ClubListActions } from './actions';

@Injectable()
export class ClubListEffects {
  #actions = inject(Actions);
  #clubApi = inject(ClubApiService);
  public readonly load$ = createEffect(() =>
    this.#actions.pipe(
      ofType(ClubListActions.loadClubs),
      exhaustMap(() =>
        this.#clubApi
          .getClubs()
          .pipe(map((data) => ClubListActions.loadClubsSuccess({ data }))),
      ),
    ),
  );
  public readonly deleteClub$ = createEffect(() =>
    this.#actions.pipe(
      ofType(ClubListActions.deleteClub),
      exhaustMap(({ id, status }) =>
        this.#clubApi.deleteClub(id, status).pipe(
          map(() => ClubListActions.loadClubs()),
          tap(() => this.showDeleteSuccess()),
          catchError(() => {
            this.showDeleteError();
            return EMPTY;
          }),
        ),
      ),
    ),
  );
  #toastService = inject(ToastService);
  #globalStore = inject(Store<GlobalState>);
  #router = inject(Router);
  public readonly editClub$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClubListActions.editClub),
        withLatestFrom(this.#globalStore.select(appSelectors.selectLanguage)),
        tap(([action, lang]) =>
          this.#router.navigate([lang, 'club', action.id, action.status]),
        ),
      ),
    { dispatch: false },
  );
  public readonly createClub$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(ClubListActions.createClub),
        withLatestFrom(this.#globalStore.select(appSelectors.selectLanguage)),
        tap(([_, lang]) => this.#router.navigate([lang, 'club', -1, 'new'])),
      ),
    { dispatch: false },
  );

  private showDeleteSuccess(): void {
    this.#toastService.showSuccess(
      'izzo.admin.shared.success.changesSaved.title',
      'izzo.admin.shared.success.changesSaved.message',
    );
  }

  private showDeleteError(): void {
    this.#toastService.showError(
      'izzo.admin.shared.errors.save.title',
      'izzo.admin.shared.errors.save.message',
    );
  }
}
